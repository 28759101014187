import React from 'react';

// Reusable KPI Card Component
const KpiCardInner = ({ title, value, icon, unit }) => (
  <div className="kpi-card">
    <div className="kpi-icon">{icon}</div>
    <div className="kpi-info">
      <div className="kpi-value">{value} {unit}</div>
      <div className="kpi-title">{title}</div>
    </div>
  </div>
);

// Dashboard Component with kpiData prop
const KpiCard = ({ kpiData, className }) => (
  kpiData ? (
    <div className={`dashboard ${className}`}>
      {kpiData.map((kpi, index) => (
        <KpiCardInner
          key={index}
          title={kpi.title}
          value={kpi.value}
          icon={kpi.icon}
          unit={kpi.unit}
        />
      ))}
    </div>
  ) : null
);

export default KpiCard;
