import React from 'react';
import { BarChart, BarLabel } from '@mui/x-charts/BarChart';
import PopulationPyramid from './PopulationPyramid';

const chartSettingSpan = {
  xAxis: [
    {
      label: 'Total reports (FTE)',
    },
  ],
  height: 400,
};

const chartSettingSalary = {
  xAxis: [
    {
      label: 'Salary in €',
    },
  ],
  height: 400,
};

const createPopulationDataSeries = (data, labelKey, dataKey1, dataKey2) => {
  return data.map((item) => ({
    [labelKey]: item[labelKey],
    [dataKey1]: item[dataKey1] || 0,
    [dataKey2]: -item[dataKey2] || 0,
  }));
};

const SpansAndLayersChart = ({ data = [] }) => {
  if (!data.length) return <p>No data available</p>;

  const avgFTEDataset = data.map((item) => ({ 
                                      ...item,
                                      value: item.avgFte || 0,
                                    }));
  const totalSalaryDataset = data.map((item) => ({
    ...item,
    value: item.totalSalary || 0,
  }));

  // Transform data for PopulationPyramid
  const totalFTEperLayer = data.map(item => ({
    layer: item.layer,
    value: item.totalFTE || 0
  }));

  return (
    <div style={{ display: 'flex', gap: '20px', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <div style={{ width: '100%' }}>
        <PopulationPyramid 
          data={totalFTEperLayer}
          legendLabel="Total reports (FTE)"
          xAxisLabel="Total reports (FTE)"
        />
        </div>
        <div style={{ width: '100%' }}>
          <PopulationPyramid 
            data={avgFTEDataset}
            legendLabel="Average Span of control"
            xAxisLabel="Average Span of control"
          />
        </div>
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ width: '100%' }}>
        <PopulationPyramid 
            data={totalSalaryDataset}
            currency="EUR"
            legendLabel="total Salary"
            xAxisLabel="Total Salary per layer"
          />
          
        </div>
        <div style={{ width: '100%' }}>
          <PopulationPyramid 
            data={totalSalaryDataset}
            currency="EUR"
            legendLabel="Average Salary"
            xAxisLabel="Average Salary per layer"
          />
        </div>
      </div>
    </div>
  );
};

export default SpansAndLayersChart;
