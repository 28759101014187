import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react';

import Select from 'react-select';
import {
  ButtonGroup,
  FormFeedback,
  FormGroup,
  Input,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import toast from 'lib/toast';
import CSVReader from 'components/CSVReader';
import Checkbox from 'components/Checkbox';
import ToggleButton from 'components/ToggleButton';
import classNames from 'classnames';


const CustomOption = ({ option, hasError, onChange }) => {
  return (
    <div className="my-3">
      <h5>Activity Format</h5>
      <FormGroup>
        <ButtonGroup>
          <ToggleButton key="activityFormat" value="Activity"
                        checked={option === 'Activity'}
                        onChange={() => onChange('Activity')}/>
          <ToggleButton key="Function-Activity" value="Function-Activity"
                        checked={option === 'Function-Activity'}
                        onChange={() => onChange('Function-Activity')}/>
        </ButtonGroup>
        {hasError ?
          <>
            <Input invalid style={{ display: 'none' }}/>
            <FormFeedback>This has duplicated activity names. You can't use
              this option</FormFeedback>
          </>
          : ''}
      </FormGroup>
    </div>
  );
};

const CSVWizard = ({
  handleSubmit,
  toggle,
  specifyColumns,
  forceHasHeader,
  activityOption,
  hasActivityError,
  setActivityFormat,
  height,
  resources,
  activities,
  threshold,
}) => {
  const [rowData, setRowData] = useState([]);
  const [filename, setFilename] = useState('');
  const [headerRows, setHeaderRows] = useState(1);
  const headers = useMemo(() => {
    if (!rowData[0]) {
      return [];
    }
    if (headerRows === 1) {
      return rowData[0] || [];
    }
    return rowData[0].map((_, idx) => rowData.slice(0, headerRows).map(row => row[idx]));
  }, [rowData, headerRows]);
  const [optColumns, setOptColumns] = useState({});
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const [hasHeader, setHasHeader] = useState(true);
  const [warningMessages, setWarningMessages] = useState({
    group: null,
    name: null,
    from: null,
    to: null,
    value: null,
    permanence: null,
    frequency: null,
    duration: null,
    twoway: null,
    relationship: null,
  });
  const [errMessages, setErrMessages] = useState({
    doubleErr: null,
    invalidResErr: null,
    invalidFunErr: null,
    invalidActErr: null,
    doubleActErr: null,
    invalidDataErr: null,
  });
  const [errIndex, setErrIndex] = useState({
    invalidFunColIndexAndNames: [],
    doubleCellRowsAndCols: [],
    invalidResCellPositions: [],
    invalidNumPositions: [],
    invalidActColumns: [],
    doubleActColumns: [],
  });
  const [relationErrIndex, setRelationErrIndex] = useState({
    invalidFrom: [],
    invalidTo: [],
    invalidImportance: [],
    invalidStability: [],
    invalidFrequency: [],
    invalidDuration: [],
    invalidDirection: [],
    invalidActivities: [],
  });
  const [errRelationMessages, setErrRelationMessages] = useState({
    fromErr: null,
    toErr: null,
    importanceErr: null,
    stabilityErr: null,
    frequencyErr: null,
    durationErr: null,
    directionErr: null,
    activitiesErr: null,
  });

  const options = useMemo(() => {
    if (hasHeader) {
      return headers.map((data, index) => {
        return {
          value: index,
          label: (Array.isArray(data) ? data.filter(c => !!c).slice().reverse().join('-') : data) || `Column ${index + 1}`,
          data,
        };
      });
    } else {
      return headers.map((_, index) => {
        return { value: index, label: `Column ${index + 1}` };
      });
    }
  }, [hasHeader, headers]);

  const gridData = useMemo(() => hasHeader ? rowData.slice(headerRows) : rowData, [hasHeader, rowData, headerRows]);

  const isResourceModal = useMemo(() => {
    if (specifyColumns.some(col => col.required && col.key === 'name')) {
      return true;
    }
    return false;
  }, [specifyColumns]);

  const isTimeUtilization = useMemo(() => {
    if (specifyColumns.some(col => col.required && col.key === 'resource')) {
      return true;
    }
    return false;
  }, [specifyColumns]);

  const isRelationships = useMemo(() => {
    if (specifyColumns?.length === 8) {
      return true;
    }
    return false;
  }, [specifyColumns]);

  const isEmployees = useMemo(
    () => specifyColumns.some(col => ['employee_number', 'email', 'email_address'].includes(col.key)),
    [specifyColumns]
  );
  

  const initOptions = useMemo(() => {
    if ((isRelationships && specifyColumns.length >= 8) || (isEmployees && specifyColumns.length >= 7)) {
      let optionsDict = {};
      specifyColumns.forEach((item, index) => {
        optionsDict[item.key] = options[index];
      });
      return optionsDict;
    }
    return null;
  }, [specifyColumns, options, isRelationships, isEmployees]);

  useEffect(() => {
    if (gridApi?.rowModel?.rowsToDisplay?.length > 0) {
      if (Array.isArray(errIndex?.doubleCellRowsAndCols) && errIndex?.doubleCellRowsAndCols?.length !== 0) {
        let ColId = gridApi?.columnModel?.displayedColumns[errIndex?.doubleCellRowsAndCols[0][1]]?.colId;
        gridApi?.ensureColumnVisible(ColId, 'middle');
        gridApi?.ensureIndexVisible(errIndex.doubleCellRowsAndCols[0][0], 'middle');
      } else if (Array.isArray(errIndex?.invalidResCellPositions) && errIndex?.invalidResCellPositions?.length !== 0) {
        let ColId = gridApi?.columnModel.displayedColumns[errIndex?.invalidResCellPositions[0][1]].colId;
        gridApi?.ensureIndexVisible(errIndex?.invalidResCellPositions[0][0], 'middle');
        gridApi?.ensureColumnVisible(ColId, 'middle');
      } else if (Array.isArray(errIndex?.invalidFunColIndexAndNames) && errIndex?.invalidFunColIndexAndNames?.length !== 0) {
        gridApi?.ensureIndexVisible(0, 'middle');
        let ColId = gridApi?.columnModel.displayedColumns[errIndex?.invalidFunColIndexAndNames[0][0]].colId;
        gridApi?.ensureColumnVisible(ColId, 'middle');
      } else if (Array.isArray(errIndex?.invalidActColumns) && errIndex?.invalidActColumns?.length !== 0) {
        gridApi?.ensureIndexVisible(0, 'middle');
        let ColId = gridApi?.columnModel.displayedColumns[errIndex?.invalidActColumns[0]].colId;
        gridApi?.ensureColumnVisible(ColId, 'middle');
      } else if (Array.isArray(errIndex?.doubleActColumns) && errIndex?.doubleActColumns?.length !== 0) {
        let ColId = gridApi?.columnModel?.displayedColumns?.find((item) => {
          if (item?.colDef?.field === errIndex?.doubleActColumns[0]?.toString()) {
            return item?.colId;
          }
          return null;
        });
        gridApi?.ensureIndexVisible(0, 'middle');
        gridApi?.ensureColumnVisible(ColId, 'middle');
      } else if (Array.isArray(errIndex?.invalidNumPositions) && errIndex?.invalidNumPositions?.length !== 0) {
        let ColId = gridApi?.columnModel.displayedColumns[errIndex?.invalidNumPositions[0][1]].colId;
        gridApi?.ensureColumnVisible(ColId, 'middle');
        gridApi?.ensureIndexVisible(errIndex.invalidNumPositions[0][0], 'middle');
      } else if (Array.isArray(errIndex?.doubleCellRowsAndCols) && errIndex?.doubleCellRowsAndCols?.length === 0) {
        let ColId = gridApi?.columnModel.displayedColumns[0].colId;
        gridApi?.ensureIndexVisible(0, 'middle');
        gridApi?.ensureColumnVisible(ColId, 'middle');
      } else if (Object.values(errIndex).every(value => value?.length === 0)) {
        gridApi?.ensureIndexVisible(0, 'middle');
        gridApi?.ensureColumnVisible('0_1', 'middle');
        setErrMessages(prevState => ({
          ...prevState,
          doubleErr: null,
          invalidResErr: null,
          invalidFunErr: null,
          invalidActErr: null,
          doubleActErr: null,
          invalidDataErr: null,
        }));
      }
    }
  }, [errIndex, gridApi]);


  useEffect(() => {
    if (isRelationships) {
      if (gridApi?.rowModel?.rowsToDisplay?.length > 0) {
        if (Array.isArray(relationErrIndex?.invalidFrom) && relationErrIndex?.invalidFrom?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidFrom[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidFrom[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidTo) && relationErrIndex?.invalidTo?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidTo[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidTo[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidImportance) && relationErrIndex?.invalidImportance?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidImportance[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidImportance[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidStability) && relationErrIndex?.invalidStability?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidStability[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidStability[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidFrequency) && relationErrIndex?.invalidFrequency?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidFrequency[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidFrequency[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidDuration) && relationErrIndex?.invalidDuration?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidDuration[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidDuration[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidDirection) && relationErrIndex?.invalidDirection?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidDirection[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidDirection[0][0], 'middle');
        } else if (Array.isArray(relationErrIndex?.invalidActivities) && relationErrIndex?.invalidActivities?.length !== 0) {
          let ColId = gridApi?.columnModel.displayedColumns[relationErrIndex?.invalidActivities[0][1]].colId;
          gridApi?.ensureColumnVisible(ColId, 'middle');
          gridApi?.ensureIndexVisible(relationErrIndex.invalidActivities[0][0], 'middle');
        } else if (Object.values(relationErrIndex).every(value => value?.length === 0)) {
          gridApi?.ensureIndexVisible(0, 'middle');
          gridApi?.ensureColumnVisible('0_1', 'middle');
          setErrRelationMessages(prevState => ({
            ...prevState,
            fromErr: null,
            toErr: null,
            importanceErr: null,
            stabilityErr: null,
            frequencyErr: null,
            durationErr: null,
            directionErr: null,
            activitiesErr: null,
          }));
        }
      }
    }
  }, [relationErrIndex, gridApi, isRelationships]);

  useEffect(() => {
    setWarningMessages({ group: null, name: null });
    setErrMessages({
      doubleErr: null,
      invalidResErr: null,
      invalidFunErr: null,
      invalidActErr: null,
      doubleActErr: null,
      invalidDataErr: null,
    });
    setErrIndex({
      invalidFunColIndexAndNames: [],
      doubleCellRowsAndCols: [],
      invalidResCellPositions: [],
      invalidNumPositions: [],
      invalidActColumns: [],
      doubleActColumns: [],
    });
    setErrRelationMessages({
      fromErr: null,
      toErr: null,
      importanceErr: null,
      stabilityErr: null,
      frequencyErr: null,
      durationErr: null,
      directionErr: null,
      activitiesErr: null,
    });
    setRelationErrIndex({
      invalidFrom: [],
      invalidTo: [],
      invalidImportance: [],
      invalidStability: [],
      invalidFrequency: [],
      invalidDuration: [],
      invalidDirection: [],
      invalidActivities: [],
    });
  }, [rowData]);

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
    setColumnApi(params.columnApi);
  }, [setGridApi, setColumnApi]);

  const onOptionChange = useCallback((event, meta) => {
    setOptColumns({
      ...optColumns,
      [meta.name]: event,
    });
    if (isResourceModal) {
      if ((event == null) && (meta.name === 'name')) {
        setErrMessages(prevState => ({
          ...prevState,
          doubleErr: null,
        }));
        setErrIndex(prevState => ({
          ...prevState,
          doubleCellRowsAndCols: [],
        }));
      } else if ((event !== null) && (meta.name === 'name')) {
        let res_index = rowData[0].indexOf(event.label);
        let doubleData = [];
        doubleData = getDoubleData(rowData?.slice(1), res_index);
        setErrMessages(prevState => ({
          ...prevState,
          doubleErr: null,
        }));
        if (Array.isArray(doubleData) && doubleData?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            doubleCellRowsAndCols: doubleData,
          }));
        }
        setWarningMessages(prevState => ({
          ...prevState,
          name: null,
        }));
      } else if ((event !== null) && (meta.name === 'group')) {
        setWarningMessages(prevState => ({
          ...prevState,
          group: null,
        }));
      } else if ((event === null) && (meta.name === 'group')) {
        console.log();
      }
    } else if (isRelationships) {
      if ((event !== null) && (meta.name === 'from')) {
        if (resources !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidResource(rowData?.slice(1), resources, res_index);
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidFrom: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            fromErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidFrom: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            from: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'from')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          fromErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidFrom: [],
        }));
      } else if ((event !== null) && (meta.name === 'to')) {
        if (resources !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidResource(rowData?.slice(1), resources, res_index);
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidTo: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            toErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidTo: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            to: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'to')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          toErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidTo: [],
        }));
      } else if ((event !== null) && (meta.name === 'value')) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'weight');
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidImportance: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            importanceErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidImportance: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            value: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'value')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          importanceErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidImportance: [],
        }));
      } else if ((event !== null) && (meta.name === 'permanence')) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'permanence');
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidStability: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            stabilityErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidStability: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            permanence: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'permanence')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          stabilityErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidStability: [],
        }));
      } else if ((event !== null) && (meta.name === 'frequency')) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'frequency');
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidFrequency: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            frequencyErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidFrequency: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            frequency: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'frequency')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          frequencyErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidFrequency: [],
        }));
      } else if ((event !== null) && (meta.name === 'duration')) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'duration');
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidDuration: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            durationErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidDuration: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            duration: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'duration')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          durationErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidDuration: [],
        }));
      } else if ((event !== null) && (meta.name === 'twoway')) {
        let res_index = rowData[0].indexOf(event.label);
        let invalidData = [];
        invalidData = checkValidTwoWay(rowData?.slice(1), res_index);
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidDirection: [],
        }));
        setErrRelationMessages(prevState => ({
          ...prevState,
          directionErr: null,
        }));
        setWarningMessages(prevState => ({
          ...prevState,
          twoway: null,
        }));
        if (Array.isArray(invalidData) && invalidData?.length !== 0) {
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidDirection: invalidData,
          }));
        }
      } else if ((event === null) && (meta.name === 'twoway')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          directionErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidDirection: [],
        }));
      } else if ((event !== null) && (meta.name === 'relationship')) {
        if (activities !== null) {
          let res_index = rowData[0].indexOf(event.label);
          let invalidData = [];
          invalidData = checkValidRelationship(rowData?.slice(1), activities, res_index);
          setRelationErrIndex(prevState => ({
            ...prevState,
            invalidActivities: [],
          }));
          setErrRelationMessages(prevState => ({
            ...prevState,
            activitiesErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidActivities: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            relationship: null,
          }));
        }
      } else if ((event === null) && (meta.name === 'relationship')) {
        setErrRelationMessages(prevState => ({
          ...prevState,
          activitiesErr: null,
        }));
        setRelationErrIndex(prevState => ({
          ...prevState,
          invalidActivities: [],
        }));
      }
    }
  }, [setOptColumns, optColumns, resources, rowData, isResourceModal, isRelationships, threshold, activities]);

  useEffect(() => {
    if (isTimeUtilization) {
      if ((resources !== null) && (headerRows === 2)) {
        let numData = gridData?.length > 0 ? getInvalidData(gridData) : null;
        let invalidFun = checkValidData(options?.slice(1), activities, 0);
        let invalidAct = checkValidData(options?.slice(1), activities, 1);
        let resData = checkValidResource(rowData?.slice(2), resources, 0);
        let doubleData = getDoubleData(rowData?.slice(2), 0);
        let validDoubleData = doubleData.filter((subArr1) => !resData.some((subArr2) => subArr1[0] === subArr2[0] && subArr1[1] === subArr2[1]));
        let doubledActData = getDoubleActData(options?.slice(1), 1);
        let validDoubleActData = doubledActData.filter((subItem1) => !invalidAct.some((subItem2) => subItem1 === subItem2));
        setErrIndex(prevState => ({
          ...prevState,
          invalidResCellPositions: resData,
          doubleCellRowsAndCols: validDoubleData,
        }));
        if (Array.isArray(invalidFun) && invalidFun?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            invalidFunColIndexAndNames: invalidFun,
          }));
        }
        if (Array.isArray(invalidAct) && invalidAct?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            invalidActColumns: invalidAct,
          }));
        }
        if (Array.isArray(validDoubleActData) && validDoubleActData?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            doubleActColumns: validDoubleActData,
          }));
        }
        if (Array.isArray(numData) && numData?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            invalidNumPositions: numData,
          }));
        }
      } else if ((resources !== null) && (headerRows === 1)) {
        let numData = gridData?.length > 0 ? getInvalidData(gridData) : null;
        let invalidAct = checkValidData(options?.slice(1), activities, null);
        let resData = checkValidResource(rowData?.slice(1), resources, 0);
        let doubleData = getDoubleData(rowData?.slice(1), 0);
        let validDoubleData = doubleData.filter((subArr1) => !resData.some((subArr2) => subArr1[0] === subArr2[0] && subArr1[1] === subArr2[1]));
        let doubledActData = getDoubleActData(options?.slice(1), null);
        let validDoubleActData = doubledActData.filter((subItem1) => !invalidAct.some((subItem2) => subItem1 === subItem2));
        setErrIndex(prevState => ({
          ...prevState,
          invalidResCellPositions: resData,
          doubleCellRowsAndCols: validDoubleData,
        }));
        if (Array.isArray(invalidAct) && invalidAct?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            invalidActColumns: invalidAct,
          }));
        }
        if (Array.isArray(validDoubleActData) && validDoubleActData?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            doubleActColumns: validDoubleActData,
          }));
        }
        if (Array.isArray(numData) && numData?.length !== 0) {
          setErrIndex(prevState => ({
            ...prevState,
            invalidNumPositions: numData,
          }));
        }
      }
    }
  }, [options, isTimeUtilization, resources, rowData, headerRows, activities, gridApi, gridData]);


  useEffect(() => {
    if (isRelationships) {
      if (options?.length >= 8) {
        setOptColumns(initOptions);
      }
    } else if (isEmployees) {
        setOptColumns(initOptions);
    }
  }, [isRelationships, isEmployees, initOptions, options]);


  useEffect(() => {
    if (!initOptions) {
      if (initOptions?.from != null) {
        console.log();
        if (rowData.length === 0) {
          return;
        }
        if (!resources) {
          let res_index = rowData[0].indexOf(initOptions?.from?.label);
          let invalidData = [];
          invalidData = checkValidResource(rowData?.slice(1), resources, res_index);
          setErrRelationMessages(prevState => ({
            ...prevState,
            fromErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidFrom: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            from: null,
          }));
        }
      }
      if (initOptions?.to != null) {
        console.log();
        if (resources !== null) {
          let res_index = rowData[0].indexOf(initOptions?.to?.label);
          let invalidData = [];
          invalidData = checkValidResource(rowData?.slice(1), resources, res_index);
          setErrRelationMessages(prevState => ({
            ...prevState,
            toErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidTo: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            to: null,
          }));
        }
      }
      if (initOptions?.value != null) {
        console.log();
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(initOptions?.value?.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'weight');
          setErrRelationMessages(prevState => ({
            ...prevState,
            importanceErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidImportance: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            value: null,
          }));
        }
      }
      if (initOptions?.permanence != null) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(initOptions?.permanence?.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'permanence');
          setErrRelationMessages(prevState => ({
            ...prevState,
            stabilityErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidStability: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            permanence: null,
          }));
        }
      }
      if (initOptions?.frequency != null) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(initOptions?.frequency?.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'frequency');
          setErrRelationMessages(prevState => ({
            ...prevState,
            frequencyErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidFrequency: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            frequency: null,
          }));
        }
      }
      if (initOptions?.duration != null) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(initOptions?.duration?.label);
          let invalidData = [];
          invalidData = checkValidThreshold(rowData?.slice(1), threshold, res_index, 'duration');
          setErrRelationMessages(prevState => ({
            ...prevState,
            durationErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidDuration: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            duration: null,
          }));
        }
      }
      if (initOptions?.twoway != null) {
        if (threshold !== null) {
          let res_index = rowData[0].indexOf(initOptions?.twoway?.label);
          let invalidData = [];
          invalidData = checkValidTwoWay(rowData?.slice(1), res_index);
          setErrRelationMessages(prevState => ({
            ...prevState,
            directionErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidDirection: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            twoway: null,
          }));
        }
      }
      if (initOptions?.relationship != null) {
        if (activities !== null) {
          let res_index = rowData[0].indexOf(initOptions?.relationship?.label);
          let invalidData = [];
          invalidData = checkValidRelationship(rowData?.slice(1), activities, res_index);
          setErrRelationMessages(prevState => ({
            ...prevState,
            activitiesErr: null,
          }));
          if (Array.isArray(invalidData) && invalidData?.length !== 0) {
            setRelationErrIndex(prevState => ({
              ...prevState,
              invalidActivities: invalidData,
            }));
          }
          setWarningMessages(prevState => ({
            ...prevState,
            relationship: null,
          }));
        }
      }
    }
  }, [initOptions, resources, rowData, threshold, activities]);

  const checkValidResource = (csvContent, resources, res_index) => {
    let invalidCellsPosition = [];
    csvContent.forEach((item, index) => {
      let first_data = item[res_index];
      if (!resources?.some(row => row.name === first_data)) {
        invalidCellsPosition.push([index, res_index]);
      }
    });
    return invalidCellsPosition;
  };

  const checkValidTwoWay = (csvContent, res_index) => {
    let invalidCellsPosition = [];
    csvContent.forEach((item, index) => {
      if ((item[res_index].toLowerCase() !== 'true') && (item[res_index].toLowerCase() !== 'false')) {
        invalidCellsPosition.push([index, res_index]);
      }
    });
    return invalidCellsPosition;
  };

  const checkValidRelationship = (csvContent, activities, res_index) => {
    let invalidCellsPosition = [];
    csvContent.forEach((item, index) => {
      let activityNames = item[res_index].split(';').map(u => u.trim());
      activityNames.forEach((actItem) => {
        if (!activities?.some(row => row.activity === actItem)) {
          invalidCellsPosition.push([index, res_index]);
        }
      });
    });
    for (let i = 0; i < invalidCellsPosition.length - 1; i++) {
      for (let j = i + 1; j < invalidCellsPosition.length; j++) {
        if (invalidCellsPosition[i][0] === invalidCellsPosition[j][0] && invalidCellsPosition[i][1] === invalidCellsPosition[j][1]) {
          invalidCellsPosition.splice(j, 1);
          j--;
        }
      }
    }
    return invalidCellsPosition;
  };
  const checkValidThreshold = (csvContent, threshold, res_index, field) => {
    let invalidCellsPosition = [];
    csvContent.forEach((item, index) => {
      let first_data = item[res_index];
      if (!threshold?.some(row => row.title.toLowerCase() === first_data.toLowerCase() && row.field === field)) {
        invalidCellsPosition.push([index, res_index]);
      }
    });
    return invalidCellsPosition;
  };
  const getDoubleData = (data, res_index) => {
    let cellPosArray = [];
    let tempArray = [];
    data.forEach((item) => {
      tempArray.push(item[res_index]);
    });
    const duplicates = Object.entries(tempArray.reduce((acc, value, index) => {
      if (!acc[value]) {
        acc[value] = [[index, res_index]];
      } else {
        acc[value].push([index, res_index]);
      }
      return acc;
    }, {})).filter(([key, value]) => value.length > 1).map(([key, value]) => ({ value: key, indexes: value }));
    duplicates.forEach((item) => {
      item.indexes.slice(1).forEach((subItem) => {
        cellPosArray.push(subItem);
      });
    });
    return cellPosArray;
  };

  const getDoubleActData = (arrData, arr_index) => {
    if (arr_index === 1) {
      let cellPosArray = [];
      let tempArray = [];
      arrData?.forEach((item, index) => {
        if (item.data.length > 0) {
          tempArray.push(item?.data[arr_index]);
        }
      });
      const duplicates = Object.entries(tempArray.reduce((acc, value, index) => {
        if (!acc[value]) {
          acc[value] = [index + 1];
        } else {
          acc[value].push(index + 1);
        }
        return acc;
      }, {})).filter(([key, value]) => value.length > 1).map(([key, value]) => ({ value: key, indexes: value }));
      duplicates.forEach((item) => {
        item.indexes.slice(1).forEach((subItem) => {
          cellPosArray.push(subItem);
        });
      });
      return cellPosArray;
    } else if (arr_index === null) {
      let cellPosArray = [];
      let tempArray = [];
      arrData.forEach((item) => {
        tempArray.push(item?.data);
      });
      const duplicates = Object.entries(tempArray.reduce((acc, value, index) => {
        if (!acc[value]) {
          acc[value] = [index + 1];
        } else {
          acc[value].push(index + 1);
        }
        return acc;
      }, {})).filter(([key, value]) => value.length > 1).map(([key, value]) => ({ value: key, indexes: value }));
      duplicates.forEach((item) => {
        item.indexes.slice(1).forEach((subItem) => {
          cellPosArray.push(subItem);
        });
      });
      return cellPosArray;
    }
  };

  useEffect(() => {
    if (columnApi) {
      columnApi.autoSizeAllColumns();
      // setOptColumns({});
    }
  }, [gridData, columnApi]);

  const isValid = useMemo(() => {
    if (!gridData?.length) {
      return false;
    }
    if (specifyColumns.some(col => col.required && !optColumns[col.key])) {
      return false;
    }
    return true;
  }, [gridData, optColumns, specifyColumns]);

  const checkValidData = (csvData, dbData, arrIndex) => {
    let invalidData = [];
    if (arrIndex === 0) {
      csvData?.forEach((item, index) => {
        if (item.data.length > 0) {
          let first_data = item?.data[arrIndex];
          if (!dbData?.some(row => row.function === first_data)) {
            invalidData.push([index + 1, first_data]);
          }
        }
      });
      return invalidData;
    } else if (arrIndex === 1) {
      csvData.forEach((item, index) => {
        if (item.data.length > 1) {
          let first_data = item.data[arrIndex];
          if (!dbData?.some(row => row.activity === first_data)) {
            invalidData.push(index + 1);
          }
        }
      });
      return invalidData;
    } else if (arrIndex === null) {
      csvData.forEach((item, index) => {
        if (item?.data?.length > 0) {
          let first_data = item.data;
          if (!dbData?.some(row => row.activity === first_data)) {
            invalidData.push(index + 1);
          }
        }
      });
      return invalidData;
    }
  };

  const getInvalidData = (data) => {
    let invalidDataPositions = [];
    let specialChars = /^[-+]?\d+?%$/;
    let specialCharsEU = /^0?,\d{1,2}$/;
    data.forEach((item, index) => {
      item.slice(1).forEach((cell, cellIndex) => {
        if (specialChars.test(cell.replace(/\s/g, ''))) {
          let num = cell.replace(/\s/g, '').slice(0, -1);
          if (!(!isNaN(num) && (num >= 0 && num <= 100))) {
            invalidDataPositions.push([index, cellIndex + 1]);
          }
        } else if (specialCharsEU.test(cell)) {
          data[index][cellIndex + 1] = (cell.replace(',', '.') * 100).toString() + '%'
        } else if (!(!isNaN(cell.replace(/\s/g, '')) && (cell.replace(/\s/g, '') >= 0 && cell.replace(/\s/g, '') <= 100))) {
          invalidDataPositions.push([index, cellIndex + 1]);
        } else {
          data[index][cellIndex + 1] = cell === '' ? '0%' : cell + '%';
        }
      });
    });
    return invalidDataPositions;
  };

  const optionsForOneLineTimeUtil = useMemo(() => {
    let customHeader = [];
    if (isTimeUtilization && headerRows === 1) {
      headers?.forEach((item, headerItem) => {
        if (headerItem === 0) {
          customHeader.push(['', item]);
        } else {
          activities?.forEach((act) => {
            if (item === act.activity) {
              customHeader.push([act.function, act.activity]);
            }
          });
        }
      });
      if (hasHeader) {
        return customHeader.map((data, index) => {
          return {
            value: index,
            label: (Array.isArray(data) ? data.filter(c => !!c).slice().reverse().join('-') : data) || `Column ${index + 1}`,
            data,
          };
        });
      }
    }

  }, [headers, activities, isTimeUtilization, headerRows, hasHeader]);

  useEffect(() => {
    if (Array.isArray(errIndex?.invalidResCellPositions) && errIndex?.invalidResCellPositions?.length !== 0) {
      setErrMessages(prevState => ({
        ...prevState,
        invalidResErr: 'Names do not match existing data!',
      }));
    }
    if (Array.isArray(errIndex?.doubleCellRowsAndCols) && errIndex?.doubleCellRowsAndCols?.length !== 0) {
      if (isTimeUtilization) {
        setErrMessages(prevState => ({
          ...prevState,
          doubleErr: 'Names are not unique in the import!',
        }));
      } else {
        setErrMessages(prevState => ({
          ...prevState,
          doubleErr: 'Duplicates found in the selected Name column!',
        }));
      }
    }
    if (Array.isArray(errIndex?.invalidFunColIndexAndNames) && errIndex?.invalidFunColIndexAndNames?.length !== 0) {
      setErrMessages(prevState => ({
        ...prevState,
        invalidFunErr: 'Functions do not match existing data!',
      }));
    }
    if (Array.isArray(errIndex?.invalidActColumns) && errIndex?.invalidActColumns?.length !== 0) {
      setErrMessages(prevState => ({
        ...prevState,
        invalidActErr: 'Activities do not match existing data!',
      }));
    }
    if (Array.isArray(errIndex?.doubleActColumns) && errIndex?.doubleActColumns?.length !== 0) {
      setErrMessages(prevState => ({
        ...prevState,
        doubleActErr: 'Activities are not unique in the import!',
      }));
    }
    if (Array.isArray(errIndex?.invalidNumPositions) && errIndex?.invalidNumPositions?.length !== 0) {
      setErrMessages(prevState => ({
        ...prevState,
        invalidDataErr: 'Data is invalid!',
      }));
    }
  }, [errIndex, isTimeUtilization]);


  useEffect(() => {
    if (Array.isArray(relationErrIndex?.invalidFrom) && relationErrIndex?.invalidFrom?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        fromErr: '"From resource" contains values that do not match a resource!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidTo) && relationErrIndex?.invalidTo?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        toErr: '"To resource" contains values that do not match a resource!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidImportance) && relationErrIndex?.invalidImportance?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        importanceErr: '"Relationship importance" contains invalid values!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidStability) && relationErrIndex?.invalidStability?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        stabilityErr: '"Relationship stability" contains invalid values!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidFrequency) && relationErrIndex?.invalidFrequency?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        frequencyErr: '"Interaction frequency" contains invalid values!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidDuration) && relationErrIndex?.invalidDuration?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        durationErr: '"Interaction duration" contains invalid values!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidDirection) && relationErrIndex?.invalidDirection?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        directionErr: '"Two-way relationship" contains invalid values!',
      }));
    }
    if (Array.isArray(relationErrIndex?.invalidActivities) && relationErrIndex?.invalidActivities?.length !== 0) {
      setErrRelationMessages(prevState => ({
        ...prevState,
        activitiesErr: '"Activity" contains values that do not match a activity!',
      }));
    }
  }, [relationErrIndex, isRelationships]);

  const columnDefs = useMemo(() => {
    if (headerRows > 2 || headerRows === 1) {
      return options.map((o, idx) => ({
        headerName: o.data,
        field: String(idx),
        key: String(idx),
        headerValueGetter: () => o.label,
        headerClass: params => {
          if (errIndex?.invalidActColumns?.find((item) => params.colDef.field === item.toString())) {
            return 'invalid_activity-header';
          }
          if (errIndex?.doubleActColumns?.find((item) => params.colDef.field === item.toString())) {
            return 'double_activity-header';
          }
          return null;
        },
        cellStyle: params => {
          if (errIndex?.invalidResCellPositions?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#cc6600', fontWeight: 'bold' };
          }
          if (errIndex?.doubleCellRowsAndCols?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#990033', fontWeight: 'bold' };
          }
          if (errIndex?.invalidNumPositions?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff0000', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidFrom?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff0000', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidTo?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff4000', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidImportance?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff8000', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidStability?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ffbf00', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidFrequency?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#bf00ff', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidDuration?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff00ff', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidDirection?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff00bf', fontWeight: 'bold' };
          }
          if (relationErrIndex?.invalidActivities?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff0080', fontWeight: 'bold' };
          }
          return null;
        },
      }));
    }
    if (headerRows === 2) {
      const columns = options.map((o, idx) => ({
        field: String(idx),
        key: String(idx),
        headerValueGetter: () => o.label,
        headerClass: params => {
          if (errIndex?.invalidActColumns?.find((item) => params.colDef.field === item.toString())) {
            return 'invalid_activity-header';
          }
          if (errIndex?.doubleActColumns?.find((item) => params.colDef.field === item.toString())) {
            return 'double_activity-header';
          }
          return null;
        },
        cellStyle: params => {
          if (errIndex?.invalidResCellPositions?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#cc6600', fontWeight: 'bold' };
          }
          if (errIndex?.doubleCellRowsAndCols?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#990033', fontWeight: 'bold' };
          }
          if (errIndex?.invalidNumPositions?.find(([rowIndexArray, columnIdArray]) => rowIndexArray === params.rowIndex && params.colDef.key === columnIdArray.toString())) {
            return { color: '#ff0000', fontWeight: 'bold' };
          }
          return null;
        },
        o: o,
      }));
      const headers = [...new Set(options.map(o => o.data[0]))];
      return headers.map(h => ({
        headerName: h,
        children: columns.filter(col => col.o.data[0] === h),
        headerClass: params => {
          if (errIndex?.invalidFunColIndexAndNames?.find(([colIndexArray, funcName]) => colIndexArray.toString() === params.colDef.children[0].field && funcName === params.colDef.headerName)) {
            return 'invalid_function-header';
          }
          return null;
        },
      }));
    }
  }, [options, headerRows, errIndex, relationErrIndex]);

  const onUpload = useCallback((write_mode) => {
    let errors = {};
    if ((specifyColumns.findIndex((param) => param.key === 'group') !== -1) && (optColumns?.group == null)) {
      errors.group = 'Please select a group!';
      setWarningMessages(prevState => ({
        ...prevState,
        group: errors.group,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'name') !== -1) && optColumns?.name == null) {
      errors.name = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        name: errors.name,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'from') !== -1) && optColumns?.from == null) {
      errors.from = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        from: errors.from,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'to') !== -1) && optColumns?.to == null) {
      errors.to = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        to: errors.to,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'value') !== -1) && optColumns?.value == null) {
      errors.value = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        value: errors.value,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'permanence') !== -1) && optColumns?.permanence == null) {
      errors.permanence = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        permanence: errors.permanence,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'frequency') !== -1) && optColumns?.frequency == null) {
      errors.frequency = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        frequency: errors.frequency,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'duration') !== -1) && optColumns?.duration == null) {
      errors.duration = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        duration: errors.duration,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'twoway') !== -1) && optColumns?.twoway == null) {
      errors.twoway = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        twoway: errors.twoway,
      }));
    }
    if ((specifyColumns.findIndex((param) => param.key === 'relationship') !== -1) && optColumns?.relationship == null) {
      errors.relationship = 'Please select a name!';
      setWarningMessages(prevState => ({
        ...prevState,
        relationship: errors.relationship,
      }));
    }
    if (Object.keys(errors).length > 0) {
      return;
    } else if (Array.isArray(errIndex?.doubleCellRowsAndCols) && errIndex?.doubleCellRowsAndCols?.length !== 0) {
      return;
    } else if (Array.isArray(errIndex?.doubleActColumns) && errIndex?.doubleActColumns?.length !== 0) {
      return;
    } else if (Array.isArray(errIndex?.invalidActColumns) && errIndex?.invalidActColumns?.length !== 0) {
      return;
    } else if (Array.isArray(errIndex?.invalidFunColIndexAndNames) && errIndex?.invalidFunColIndexAndNames?.length !== 0) {
      return;
    } else if (Array.isArray(errIndex?.invalidResCellPositions) && errIndex?.invalidResCellPositions?.length !== 0) {
      return;
    } else if (Array.isArray(errIndex?.invalidNumPositions) && errIndex?.invalidNumPositions?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidFrom) && relationErrIndex?.invalidFrom?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidTo) && relationErrIndex?.invalidTo?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidImportance) && relationErrIndex?.invalidImportance?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidStability) && relationErrIndex?.invalidStability?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidFrequency) && relationErrIndex?.invalidFrequency?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidDuration) && relationErrIndex?.invalidDuration?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidDirection) && relationErrIndex?.invalidDirection?.length !== 0) {
      return;
    } else if (Array.isArray(relationErrIndex?.invalidActivities) && relationErrIndex?.invalidActivities?.length !== 0) {
      return;
    }

    if (!gridData?.length) {
      toast('Nothing to import!');
      return;
    }
    const specifiedColumns = {};
    specifyColumns.forEach(col => {
      if (optColumns[col.key]) {
        specifiedColumns[col.key] = optColumns[col.key].value;
      }
    });
    if (isTimeUtilization) {
      specifiedColumns['resource'] = options[0].value;
    }
    if (isTimeUtilization && headerRows === 1) {
      handleSubmit({
        write_mode: write_mode,
        rows: gridData,
        headers: optionsForOneLineTimeUtil.map(o => o.data || o.label),
        hasHeader,
        ...specifiedColumns,
      });
    } else {
      handleSubmit({
        write_mode: write_mode,
        rows: gridData,
        headers: options.map(o => o.data || o.label),
        hasHeader,
        ...specifiedColumns,
      });
    }

  }, [gridData, handleSubmit, optColumns, options, specifyColumns, hasHeader, errIndex, isTimeUtilization, headerRows, optionsForOneLineTimeUtil, relationErrIndex]);

  const agGridClass = classNames({
    'col-12': isTimeUtilization,
    'col-8': !isTimeUtilization,
  });

  return (
    <>
      <ModalBody>
        <div style={{ marginBottom: 5 }} className="row">
          <div className="col-2">
            <CSVReader
              label="Select CSV File"
              onFileLoaded={(res, selectedFile) => {
                setOptColumns({});
                const firstNotEmptyIndex = res.findIndex(u => u[0].trim());
                setHeaderRows(firstNotEmptyIndex + 1);
                setRowData(res);
                if (!filename) {
                  setFilename(selectedFile);
                }
              }}
              onError={(res) => {
                console.log(res);
              }}
              parserOptions={{}}
            />
          </div>

        </div>
        <div className="row">
          <div className={agGridClass}>
            <div
              className="ag-theme-alpine"
              style={{ height: height || 500, width: '100%' }}
            >
              <AgGridReact
                defaultColDef={{
                  sortable: false,
                  resizable: true,
                }}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                rowData={gridData}
                overlayNoRowsTemplate='<span class="ag-overlay-no-rows-center">Please select a file to import</span>'
              />
            </div>
          </div>
          {!isTimeUtilization && (
            <div className="col-4">
              {!forceHasHeader && (
                <div className="d-flex align-items-center">
                  <label
                    className="form-check-label"
                    htmlFor="importCsvHasHeader"
                  >
                    CSV file has headers
                  </label>
                  <Checkbox
                    checked={hasHeader}
                    onChange={(e) => setHasHeader(e.target.checked)}
                    className="form-check-input ms-3"
                    id="importCsvHasHeader"
                  />
                </div>
              )}
              {activityOption && <CustomOption
                option={activityOption}
                hasError={hasActivityError}
                onChange={setActivityFormat}
              />}
              {specifyColumns.map((col => (
                <div className="mt-2 align-items-center" key={col.key}>
                  <label
                    id="label-department"
                    htmlFor={`department-input-${col.key}`}
                  >
                    {col.label}
                    {col.required &&
                      <span className="ms-1" style={{ color: 'red' }}>*</span>}
                  </label>
                  <Select
                    className={classNames('mt-2 import-survey-department-select', { 'is-invalid': warningMessages[col.key] })}
                    aria-labelledby="label-department"
                    name={col.key}
                    inputId={`department-input-${col.key}`}
                    placeholder={col.placeholder || col.label}
                    options={options}
                    onChange={onOptionChange}
                    value={(Object.keys(optColumns).length !== 0) && optColumns[col.key]}
                    isClearable
                    styles={{
                      invalid: (provided, state) => ({
                        ...provided,
                        display: warningMessages ? 'block' : 'none',
                      }),
                    }}
                  />
                  {col.key === 'group' && warningMessages?.group &&
                    <FormFeedback style={{ display: 'block' }}> {warningMessages.group} </FormFeedback>}
                  {col.key === 'name' && warningMessages?.name &&
                    <FormFeedback style={{ display: 'block' }}> {warningMessages.name} </FormFeedback>}
                </div>
              )))}
            </div>)}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="d-flex w-100">
          <div className="align-items-center d-flex flex-column w-75">
            {
              <span className="text-danger align-items-center">
                {
                  <ul className="error-list">
                    {errMessages && Object.entries(errMessages).map(([key, value]) => {
                      if (value != null) {
                        return <li key={key} className={key}>{value}</li>;
                      }
                      return null;
                    })}
                    {errRelationMessages && Object.entries(errRelationMessages).map(([key, value]) => {
                      if (value != null) {
                        return <li key={key} className={key}>{value}</li>;
                      }
                      return null;
                    })}
                  </ul>
                }
              </span>
            }
          </div>
          <div className="d-flex align-items-end">
            <button style={{ maxHeight: 40 }} className="button me-2" onClick={() => onUpload('overwrite')}>
              overwrite
              <span className="material-symbols-outlined">
              refresh
              </span>
            </button>
            {!isRelationships && !isTimeUtilization && (
            <button style={{ maxHeight: 40 }} className="button me-2" onClick={() => onUpload('extend')}>
              extend
              <span className="material-symbols-outlined">
              add
              </span>
            </button>
            )}
            <button style={{ maxHeight: 40 }} className="button" onClick={toggle}>
              Cancel
            </button>
          </div>
        </div>
      </ModalFooter>
    </>
  );
};

export default CSVWizard;
