import React, { useState, useEffect, useLayoutEffect, useRef, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { ButtonGroup } from 'reactstrap';
import ToggleButton from 'components/ToggleButton';
import AggridContextMenu from 'components/AggridContextMenu';
import { getTermFilteredActivities, getCountByWorkEffort } from 'lib/helper';
import { FTE_HOURS_PER_MONTH } from 'lib/constants';

const _resource = 'resource';
const _interface = 'interface';
const _relationship = 'relationship';
const _workeffort = 'workeffort';
const _workeffortCost = 'workeffortCost';
const _automationPotentialSavings = 'automationPotentialSavings'
const _automationPotential = 'automationPotential';

function getRelationLinkPair(initCount, nodes, links) {
  let relationCount = {...initCount};
  let participantGroupName = {};
  nodes.forEach((node) => {
    participantGroupName[node.id] = node.groupName;
  });
  links.forEach((link) => {
    if (link.value === 0) return;
    if (
      !participantGroupName[link.sourceId]
      || !participantGroupName[link.targetId]
    ) return;
    link['relationship'].forEach((activity) => {
      relationCount[activity]?.[participantGroupName[link.sourceId]]?.push(link);
      if (participantGroupName[link.sourceId] !== participantGroupName[link.targetId]) {
        relationCount[activity]?.[participantGroupName[link.targetId]]?.push(link);
      }
    });
  });
  return relationCount;
}

function getCountByInterface(
  initCount, nodes, links, activityIds, groupNames
) {
  let relationCount = getRelationLinkPair(initCount, nodes, links);
  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      const ids = relationCount[id][group].map(
        u => u.sourceId < u.targetId
          ? `${u.sourceId}-${u.targetId}`
          : `${u.targetId}-${u.sourceId}`
      )
      relationCount[id][group] = [...new Set(ids)].length;
    });
  });
  return relationCount;
}

function getCountByRelationship(
  initCount, nodes, links, activityIds, groupNames
) {
  let relationCount = getRelationLinkPair(initCount, nodes, links);

  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      let fteInvolvedByGroup = {};
      relationCount[id][group].forEach(u => {
        const key = u.sourceId < u.targetId
          ? `${u.sourceId}-${u.targetId}`
          : `${u.targetId}-${u.sourceId}`;
        fteInvolvedByGroup[key] = Math.max(
          (fteInvolvedByGroup[key] || 0),
          u.num_fte_involved || 2,
        );
      })
      relationCount[id][group] = Object.values(fteInvolvedByGroup).reduce(
        (a, b) => a + b, 0
      );
    });
  });
  return relationCount;
}

function getCountByResource(initCount, nodes, activityIds, groupNames) {
  let relationCount = {...initCount};
  nodes.forEach(node => {
    Object.keys(node.activityUtilization || {}).forEach(activity => {
      const fullTimeEquivalent = +node.fte || 1;
      if (relationCount[activity]?.[node.groupName] && +node.activityUtilization[activity].replace('%', '') > 0) {
        if (!relationCount[activity][node.groupName].fullTimeEquivalentSum) {
          relationCount[activity][node.groupName].fullTimeEquivalentSum = 0;
        }
        relationCount[activity][node.groupName].fullTimeEquivalentSum += fullTimeEquivalent;
      }
    })
  });

  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      relationCount[id][group] = relationCount[id][group].fullTimeEquivalentSum || 0;
    });
  });
  return relationCount;
}

function getWorkEffortSalaryCost(initCount, nodes, activityIds, groupNames) {
  let activitySalaryCost = { ...initCount };
  nodes.forEach(node => {
    Object.keys(node.activityUtilization || {}).forEach(activity => {
      const fte = +node.fte;
      const monthlySalary = (node.salary / 1650) * 160;
      const timeUtilization = (+node.activityUtilization[activity].replace('%', '') / 100);
      const monthlySalaryCost = timeUtilization * monthlySalary * fte; 
      if (activitySalaryCost[activity]?.[node.groupName] && monthlySalaryCost > 0) {
        activitySalaryCost[activity][node.groupName].push(monthlySalaryCost);
      }
    })
  });

  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      let percents = activitySalaryCost[id][group] || [];
      activitySalaryCost[id][group] = Math.round(percents.reduce((a, b) => a + b, 0));
    });
  });
  return activitySalaryCost;
}

function getCountByAutomationPotential(initCount, nodes, activityIds, activities, groupNames) {
  let relationCount = { ...initCount };
  nodes.forEach(node => {
    Object.keys(node.activityUtilization || {}).forEach(activity_id => {
      const activity = activities.find(activity => activity.id === parseInt(activity_id));
      if (activity === undefined) {
        return;
      }
      const fte = +node.fte;
      const percent = +parseFloat(node.activityUtilization[activity_id]) * fte * (parseFloat(activity.automation_potential) / 100);
      if (relationCount[activity_id]?.[node.groupName] && percent > 0) {
        relationCount[activity_id][node.groupName].push(percent);
      }
    })
  });

  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      let percents = relationCount[id][group] || [];
      relationCount[id][group] = percents.reduce((a, b) => a + b, 0) * FTE_HOURS_PER_MONTH / 100;
    });
  });
  return relationCount;
}

function getAutomationPotentialSavings(initCount, nodes, activityIds, activities, groupNames) {
  let automationPotentialSavings = { ...initCount };
  const salaryCosts = getWorkEffortSalaryCost(initCount, nodes, activityIds, groupNames);
  Object.entries(salaryCosts).forEach(([activityId, salaryCost]) => {
    const automationpotentialPct = +(activities.find(a => String(a.id) === String(activityId)).automation_potential).replace('%', '') / 100;
    Object.entries(salaryCost).forEach(([group, cost]) => {
      if (!automationPotentialSavings[activityId]) {
        automationPotentialSavings[activityId] = {};
      }
      if (!automationPotentialSavings[activityId][group]) {
        automationPotentialSavings[activityId][group] = 0;
      }
      automationPotentialSavings[activityId][group] = cost * automationpotentialPct;
    }
    );
  });  

  return automationPotentialSavings;
}

function getGridData(
  nodes,
  links,
  groups,
  responsibilityMap,
  activities,
  activityIds,
  byFunction,
  indicator,
) {
  const groupNames = groups.map(group => group.name);
  let relationCount = {};
  let max = 0;
  let data = [];

  activityIds.forEach((id) => {
    groupNames.forEach((group) => {
      if (!relationCount[id]) {
        relationCount[id] = {};
      }
      relationCount[id][group] = [];
    });
  });
  if (indicator === _interface) {
    relationCount = getCountByInterface(
      relationCount, nodes, links, activityIds, groupNames,
    );
  } else if (indicator === _relationship) {
    relationCount = getCountByRelationship(
      relationCount, nodes, links, activityIds, groupNames,
    );
  } else if (indicator === _resource) {
    relationCount = getCountByResource(
      relationCount, nodes, activityIds, groupNames,
    );
  } else if (indicator === _workeffort) {
    relationCount = getCountByWorkEffort(
      relationCount, nodes, activityIds, groupNames,
    );
  } else if (indicator === _workeffortCost) {
    relationCount = getWorkEffortSalaryCost(
      relationCount, nodes, activityIds, groupNames,
    );
  } else if (indicator === _automationPotential) {
    relationCount = getCountByAutomationPotential(
      relationCount, nodes, activityIds, activities, groupNames,
    );
  } else if (indicator === _automationPotentialSavings) {
    relationCount = getAutomationPotentialSavings(
      relationCount, nodes, activityIds, activities, groupNames,
    );
  }

  let responseCount = {};
  activities.forEach((activity) => {
    groupNames.forEach((group) => {
      if (!responseCount[activity.id]) {
        responseCount[activity.id] = {};
      }
      if (responsibilityMap?.[activity.function || 'All']?.[activity.activity]?.[group]) {
        responseCount[activity.id][group] = 1;
      }
    });
  });

  Object.keys(relationCount).forEach((activityId) => {
    max = Math.max(max, ...Object.values(relationCount[activityId]));
    const activity = activities.find(item => item.id === +activityId);
    if (!activity) {
      return;
    }
    const row = {
      id: activityId,
      title: activity.activity,
      cFunction: activity.function,
    };
    groupNames.forEach(group => {
      row[group] = {
        value: relationCount[activityId][group],
        resp: responseCount[activityId][group] || 0,
      };
    });
    data.push(row);
  });

  if (byFunction) {
    const uniqueActivityFunctions = [...new Set(data.map(item => item.cFunction))];
    const dataByActivity = [];
    max = 0;
    uniqueActivityFunctions.forEach((func) => {
      const sameFunctionItems = data.filter((item) => item.cFunction === func);
      const cellValue = {};
      Object.keys(sameFunctionItems[0]).forEach(key => {
        if (key === 'title' || key === 'cFunction' || key === 'id') {
          return;
        }
        cellValue[key] = sameFunctionItems.map(item => item[key])
          .reduce((a, b) => ({
            value: a.value + b.value,
            resp: a.resp + b.resp,
          }), { value: 0, resp: 0 });
        max = Math.max(max, cellValue[key].value);
      });
      dataByActivity.push({
        id: func,
        cFunction: func,
        ...cellValue,
      });
      

    });
    return { max, data: dataByActivity };
  }

  // Add total row and total column
  const totalRow = {
    id: 'total',
    title: 'Total',
    cFunction: 'Total',
  };
  groupNames.forEach((group) => {
    let totalValue = 0;
    data.forEach(row => {
      totalValue += row[group].value;
    });
    totalRow[group] = {
      value: totalValue,
      resp: 0,
    };
  });
  data.push(totalRow);

  return { max, data };
}


const AlignmentTab = (props) => {
  const { nodes, links, termFilter, data, groupMapping, updateResponsibilityMap, isMaximized, activities, setAgentActivity, setAgentTeam, setActiveTab } = props;
  const { activityMap } = data;
  const [columnDefs, setColumnDefs] = useState([]);
  const [size, setSize] = useState([0, 0]);
  const rowSorting = {};
  const activityIds = Object.keys(activityMap);
  const [responsibilityMap, setResponsibilityMap] = useState(data.responsibility_map);
  const [indicator, setIndicator] = useState('workeffort');
  const [contextMenu, setContextMenu] = useState(null);
  
  const [responsibilitiesType, setResponsibilitiesType] = useState('activities');
  let gridData;

  const activityColumns = useMemo(() => [
    {
      headerName: 'Function',
      minWidth: 200,
      width: 200,
      field: 'cFunction',
      tooltipField: 'cFunction',
      filter: 'agTextColumnFilter',
      pinned: 'left',
    }, {
      headerName: 'Activity',
      minWidth: 200,
      width: 200,
      field: 'title',
      rowDrag: true,
      tooltipField: 'title',
      filter: 'agTextColumnFilter',
      cellStyle: { cursor: 'pointer' },
      pinned: 'left',
    }, {
      headerName: 'Total',
      field: 'total',
      minWidth: 150,
      width: 150,
      pinned: 'left',
      valueGetter: (params) => {
        const valuesToSum = Object.keys(params.data)
          .filter(key => key !== 'total')
          .map(key => Number(params.data[key]?.value || 0));
        return valuesToSum.reduce((sum, value) => sum + value, 0); // return raw number
      },
      valueFormatter: (params) => {
        if ([_workeffortCost, _automationPotentialSavings].includes(indicator)) {
          return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(params.value);
        }
        return params.value.toFixed(0);
      }
    }    
  ], [indicator]);
  
  const GradientAgCell = (props) => {
    const { value, context, node } = props;
    const { max } = context;

    const printValue = (value, indicator) => {
      if ([_workeffortCost, _automationPotentialSavings].includes(indicator)) {
        return new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(value.value);
      }
    
      return value.value.toFixed(0);
    };
    
    
    // Check if the row title is 'Total'
    const isTotalRow = node?.data?.title === 'Total';
  
    return value && max > 0 ? (
      <div style={{
        background: value.resp ? 'rgba(52, 227, 159)' : 'transparent',
        width: 'inherit',
      }}>
        <div style={{
          background: isTotalRow 
            ? 'none' 
            : `linear-gradient(to right, #4963FE, rgba(0,0,0,0) ${value.value * 100 / max}%), linear-gradient(to right, #4963FE, rgba(0,0,0,0) ${value.value * 100 / max}%)`,
          textAlign: 'right',
          padding: '0 5px',
        }}>
          
          {printValue(value, indicator)}
        </div>
      </div>
    ) : null;
  };
  
  
  const sumAutomationPotential = (activities) => {
    if (!activities) {
      return 0;
    }
    const aut_pot = activities.map(a => a.automation_potential).reduce((acc, value) => {
      let numericValue = parseFloat(value);
      if (!isNaN(numericValue)) {
          return acc + (numericValue / 100);
      }
      return acc;
    }, 0);
    return aut_pot;
  };

  const onCellClick = (event) => {
    const { column, data, context } = event;
    let columns = [...activityColumns];
    // Can be replaced with useref
    if (context.showByActivity) {
      columns = columns.slice(1, 2);
    }

    if (column.colId === 'title') {
      const tmp = JSON.parse(JSON.stringify(data));
      const newSorting = tmp.title;
      let sortDirection = 1;
      delete tmp.title;
      delete tmp.cFunction;
      delete tmp.id;
      const values = Object.keys(tmp).map((item) => ({
        name: item,
        value: tmp[item],
      }));
      if (newSorting === rowSorting.field) {
        sortDirection = 0 - rowSorting.direction;
      }
      values.sort((a, b) => (b.value.value - a.value.value) * sortDirection);
      values.forEach((item) => {
        columns.push({
          headerName: item.name,
          headerTooltip: item.name,
          field: item.name,
          flex: 1,
          cellRenderer: GradientAgCell,
          comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
            if (valueA.value === valueB.value) {
              return 0;
            }
            return valueA.value > valueB.value ? 1 : -1;
          },
        });
      });
      setColumnDefs(columns);
      rowSorting.field = newSorting;
      rowSorting.direction = sortDirection;
    } else if (column.colId !== 'cFunction') {
      let tmpMapping = { ...(context.responsibilityMap || {}) };
      let f = data['cFunction'] || 'All';
      let t = data['title'];
      if (!tmpMapping[f]) {
        tmpMapping[f] = {};
      }
      if (!tmpMapping[f][t]) {
        tmpMapping[f][t] = {};
      }
      tmpMapping[f][t][column.colId] = tmpMapping[f][t][column.colId] ? 0 : 1;
      setResponsibilityMap(tmpMapping);
      updateResponsibilityMap(tmpMapping);
    }
  };

  useEffect(() => {
    let columns = [...activityColumns];
    if (responsibilitiesType === 'functions') {
      columns = columns.slice(0, 1);
    }
    groupMapping.forEach((group) => {
      const groupName = group.name;
      columns.push({
        headerName: groupName,
        headerTooltip: groupName,
        field: groupName,
        flex: 1,
        cellRenderer: GradientAgCell,
        comparator: (valueA, valueB, nodeA, nodeB, isInverted) => {
          if (valueA.value === valueB.value) {
            return 0;
          }
          return valueA.value > valueB.value ? 1 : -1;
        },
      });
    });
    setColumnDefs(columns);
  }, [nodes, responsibilitiesType, termFilter, groupMapping, activityColumns, indicator]);

  const tableRef = useRef();

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight - (isMaximized ? 150 : 350)]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [isMaximized]);

  gridData = getGridData(
    nodes,
    links,
    groupMapping,
    responsibilityMap,
    getTermFilteredActivities(activities, termFilter),
    activityIds,
    responsibilitiesType === 'functions',
    indicator,
  );

  const onCellContextMenu = (event) => {
    setContextMenu({
      x: event.event.clientX,
      y: event.event.clientY,
      columnData: event.column,
      rowData: event.data,
      activityId: event.data.id,
      department: event.column.colId,
    });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
  };  

  return (
    <div className="activity-participation design-strategy">
      <div className="row">
        {/*}
        <div className="col-md-3 legends">
          <h5>Legend</h5>
          <div className="d-flex">
            <div className="strategy-legend responsible"></div>
            <div>Responsible Unit</div>
          </div>
          <div className="d-flex mt-1">
            <div className="strategy-legend unit">2</div>
            <div>Unit Contribution</div>
          </div>
        </div>
        */}
        <div className="col-md-6">
          <h5>Contribution indicator</h5>
          <ButtonGroup>
            <ToggleButton
              value="Work effort in hours/month"
              id={_workeffort}
              checked={indicator === _workeffort}
              onChange={() => setIndicator(_workeffort)}
            />
            <ToggleButton
              value="Work effort cost per month"
              id={_workeffortCost}
              checked={indicator === _workeffortCost}
              onChange={() => setIndicator(_workeffortCost)}
            />
            <ToggleButton
              value="Automation potential in hours/month"
              id={_automationPotential}
              checked={indicator === _automationPotential}
              onChange={() => setIndicator(_automationPotential)}
            />
            <ToggleButton
              value="Automation potential savings per month"
              id={_automationPotentialSavings}
              checked={indicator === _automationPotentialSavings}
              onChange={() => setIndicator(_automationPotentialSavings)}
            />
            <ToggleButton
              value="Number of resources"
              id={_resource}
              checked={indicator === _resource}
              onChange={() => setIndicator(_resource)}
            />
            <ToggleButton
              value="Number of interfaces"
              id={_interface}
              checked={indicator === _interface}
              onChange={() => setIndicator(_interface)}
            />
            <ToggleButton
              value="Number of relationships"
              id={_relationship}
              checked={indicator === _relationship}
              onChange={() => setIndicator(_relationship)}
            />
          </ButtonGroup>
        </div>
        <div className="col-md-3 ms-auto">
          <h5>Group by</h5>
          <ButtonGroup>
            <ToggleButton
              value="Function"
              id="functions"
              checked={responsibilitiesType === 'functions'}
              onChange={() => setResponsibilitiesType('functions')}
            />
            <ToggleButton
              value="Activity"
              id="activities"
              checked={responsibilitiesType === 'activities'}
              onChange={() => setResponsibilitiesType('activities')}
            />
          </ButtonGroup>
        </div>
      </div>
      <div
        className="ag-theme-alpine table-bar"
        ref={tableRef}
        style={{ height: size[1], width: 'auto' }}
        onContextMenu={onContextMenu}
      >
        <AgGridReact
          rowData={gridData?.data || []}
          gridOptions={{
            onCellClicked: onCellClick,
            rowDragManaged: true,
            getRowId: (d) => d.data.id,
            postSort: (nodes) => {
              const totalIndex = nodes.findIndex(n => n.data?.id === 'total');
              if (totalIndex > -1) {
                const totalNode = nodes.splice(totalIndex, 1)[0];
                nodes.push(totalNode);
              }
            },
          }}
          defaultColDef={{
            width: 140,
            minWidth: 140,
            sortable: true,
            resizable: true,
          }}
          context={{
            max: gridData?.max,
            showByActivity: responsibilitiesType === 'functions',
            responsibilityMap,
          }}
          columnDefs={columnDefs}
          enableBrowserTooltips={true}
          // onCellContextMenu={onCellContextMenu} // TODO: Make create Agent accessible outside of AI agent tab
        />
        {contextMenu && (
            <AggridContextMenu
              buttonLabel="Create AI Agent"
              x={contextMenu.x}
              y={contextMenu.y}
              onClose={closeContextMenu}
              onGenerate={() => {
                setAgentActivity(activities.find(activity => activity.id === parseInt(contextMenu.activityId)));
                setAgentTeam(groupMapping.find(group => group.name === contextMenu.department));
                setActiveTab(21);
              }}
              />
        )}
      </div>
    </div>
  );
};

export default AlignmentTab;
