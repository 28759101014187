import React, { useState, Component } from 'react';
import { connect } from 'react-redux';
import {
  Modal, ModalBody, ModalFooter,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';
import Select from 'react-select';

import Button from 'components/Button';
import Error from 'components/Error';
import Loading from 'components/Loading';
import TimeUtilizationTab from 'pages/Design/components/TimeUtilizationTab';
import GraphView from 'components/AdjacentMatrix/GraphView';
import { Portalable } from 'components/Portal';
import TextInput from 'components/TextInput';
import withRouter from 'lib/withRouter';
import LodashFunctions from 'lib/lodashFunctions';
import { getDesignOrg, saveDesignOrg } from 'redux/actions/design';
import OrganizationTab from './components/OrganizationTab';
import EmployeesTab from '../Describe/components/EmployeesTab';
import SpansAndLayersTab from './components/SpansAndLayersTab';
import OptimizeTypes from './components/OptimizeTypes';
import LabelSelector from './components/LabelSelector';
import AlignmentTab from './components/AlignmentTab';
import AutomationTab from './components/AutomationTab';
import ActivitiesTab from './components/ActivitiesTab';
import AIAgentTab from './components/AIAgentTab';
import DashboardTab from './components/DashboardTab';
import OrgChartTab from '../Describe/components/OrgChartTab'
import DesignMatrixView from './DesignMatrixView';
import {
  getColorByName,
  getTermFilteredActivities,
  getTermFilteredLinks,
  getTermFilteredNode,
  getTermFilteredScenarioGroups,
  toServerLinks,
} from 'lib/helper';
import { setGraphFilter, setEfficiencyCoefficient } from 'redux/actions/design';
import {
  ReactComponent as GraphToggleMaximize,
} from 'img/icons/graphmaximize.svg';
import {
  ReactComponent as GraphToggleMinimize,
} from 'img/icons/graphminimize.svg';
import ProgressLineChart from './components/ProgressLineChart';
import _ from 'lib/lodashFunctions';
import { createScenario, getScenarios, changeScenario } from 'api/user';
import { arrayReducer } from 'lib/helper';



const ProgressChart = (props) => {
  const { data } = props;
  return <>
    <div className="design-org">
      <div className="content__header py-4 d-lg-flex align-items-center">
        <h3 className="ellipsis">
          {data.title}
        </h3>
      </div>
      <div style={{ height: 400 }}>
        <ProgressLineChart id={data.id} />
      </div>
    </div>
  </>;
};

const NewScenarioModal = (props) => {
  const { showModal, toggle, design, scenario, onCreateScenario } = props;
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  function onCreate() {
    setLoading(true);
    createScenario({
      name,
      design,
      scenario: scenario > 0 ? scenario : undefined,
    })
      .then((res) => {
        onCreateScenario({
          id: res.id,
          name: name,
        });
        setName('');
        toggle();
      })
      .catch()
      .finally(() => setLoading(false));
  }

  return (
    <Modal isOpen={showModal} toggle={toggle} style={{ width: 600 }}>
      <ModalHeader toggle={toggle}>Add New Scenario</ModalHeader>
      <ModalBody>
        {
          loading
            ? <Loading size="sm" />
            : <TextInput
              type="text"
              placeholder="Enter name"
              onChange={(e) => setName(e.target.value)}
              name="name"
              value={name}
              autoFocus
            />
        }
      </ModalBody>
      <ModalFooter>
        <Button
          className="button"
          text="Create"
          onClick={onCreate}
          disabled={loading}
        />
        {' '}
        <Button className="button" onClick={toggle} text="Cancel" />
      </ModalFooter>
    </Modal>
  );
};

class DesignOrg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: ['first_name', 'last_name', 'job_title', 'title'],
      activeTab: 1,
      activeActivity: null,
      agentActivity: null,
      agentTeam: null,
      isMaximized: false,
      responsibilityMap: null,
      showNewScenario: false,
      currentScenario: 'default',
      scenarios: [],
      shouldHideOptimizeTypes: true,
      efficientCoefficient: undefined,
      selectedAgent: {}
    };
    let params = this.props.match.params;
    this.props.designOrg.loading = true;
    getScenarios(params.design).then(res => {
      this.setState({ scenarios: res }, () => {
        this.props.getDesignOrg(params.design);
        if (res.length > 0) {
          this.setState({
            currentScenario: res.filter(item => item.is_default)
              .map(item => ({
                value: item.id,
                label: item.name,
              }))[0]
          })
        }
      });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {designOrg} = nextProps;
    if (
      designOrg.data?.efficiency_coefficient
      && designOrg.data.efficiency_coefficient !== this.state.efficientCoefficient
    ) {
      this.setState({ efficientCoefficient: designOrg.data.efficiency_coefficient });
      this.props.setEfficiencyCoefficient(designOrg.data.efficiency_coefficient * 100);
    }
  }

  onUpdateResponsibilityMap = (data) => {
    this.setState({
      responsibilityMap: data,
    });
  };

  getResultData = (data) => {
    // TODO check if it's using group mapping from scenario data
    let groupMapping = data.assigned.map((group) => ({
      id: group.groupId,
      name: group.group_name,
    }));
    let nodes = LodashFunctions.flatten(
      data.assigned.map((group, key) =>
        group.items.map((item) => {
          return {
            ...item,
            id: item.id,
            group: group.groupId,
            groupName: group.group_name
          }
        }),
      ),
    );
    if (data.unassigned.length) {
      groupMapping = [...groupMapping, { id: 'gr', name: 'ungrouped' }];
      const unassigned_nodes = data.unassigned.map((item) => ({
        ...item,
        id: item.id,
        group: 'gr',
      }));
      nodes = [...nodes, ...unassigned_nodes];
    }

    let time_utilization = {}
    nodes.forEach(n => {
      time_utilization[n.id] = n.time_utilization;
    })

    return {
      valid: true,
      group_mapping: groupMapping,
      nodes,
      time_utilization,
      activities: data.activities,
    };
  };

  filterData = (data) => {
    /*let nodes2 = data.nodes.map(n => {
      let newNode = {
        id: n.id,
        division: n.division,
        group: n.group,
        location: n.location,
        name: n.name,
        title: n.title,
        fte: n.fte,
        subscriber_status: n.subscriber_status,
      }
      const labels = Object.keys(data.result.save?.defaultLabels || {})
      labels.forEach(l => newNode[l] = n[l]);
      return newNode;
    });*/
    let nodes = data.nodes;

    // TODO review payload structure
    let payload = {
      ...toServerLinks({
        nodes,
        links: data.links,
        save: data.result.save,
      }),
      group_mapping: data.group_mapping,
      optimize_type: data.optimize_type,
      activities: data.activities,
      time_utilization: data.time_utilization,
    };
    if (data.responsibility_map) {
      payload.responsibility_map = data.responsibility_map;
    }

    return payload;
  };

  submitDesign = (data, run) => {
    // TODO check if links is needed
    const {
      valid,
      group_mapping,
      nodes,
      time_utilization,
      activities,
    } = this.getResultData(data);

    const { defaultLabels } = data;

    const { designOrg } = this.props;
    if (valid) {
      const submitData = {
        ...designOrg.data,
        nodes,
        result: {
          ...designOrg.data.result,
          save: {
            defaultLabels
          },
        },
        responsibility_map: this.state.responsibilityMap || designOrg.data.responsibility_map,
        group_mapping,
        time_utilization,
        activities,
      };

      this.props.saveDesignOrg(
        designOrg.data.id,
        this.filterData(submitData),
        run,
      );
    } else {
      console.warn('Submitted design is not valid')
    }
  };

  handleOptimizeTypeChange = (id) => {
    this.props.saveDesignOrg(
      this.props.designOrg.data.id,
      { optimize_type: id },
      this.props.designOrg.data.run,
    );
  };

  setLabels = (labels) => {
    this.setState(
      {
        labels: labels,
      },
      this.groupListRef?.updateCardSize,
    );
  };

  setActiveTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  setMaximized = (isMaximized) => {
    this.setState({
      isMaximized,
    });
  };

  setActivities = (activities) => {
    this.props.saveDesignOrg(
      this.props.designOrg.data.id,
      { activities },
      this.props.designOrg.data.run,
    );
  }

  setAutomationActivity = (activity) => {
    this.setState({ activeActivity: activity });
    this.setActiveTab(7);
  };

  setAgentActivity = (activity) => {

    // Set both states at once and use the callback to call setActiveTab
    this.setState({
        agentActivity: activity,
    })
  };

  setAgentTeam = (team) => {

    // Set both states at once and use the callback to call setActiveTab
    this.setState({
        agentTeam: team
    }, () => {
        // State updates are complete, now call setActiveTab
        this.setActiveTab(21);
    });
  };

  setSelectedAgent = (updateFunction) => {
    this.setState((prevState) => {
      const prevAgent = prevState.selectedAgent;
      return { selectedAgent: updateFunction(prevAgent) };
    });
  };
  

  addEmployee = (employee, relationships = []) => {
    const designOrg = this.props.designOrg;
    const scenarios = designOrg.data.scenarios;
    const currentScenario = scenarios.find(scenario => scenario.id === this.state.currentScenario['value']);
    const newNodes = [employee, ...designOrg.data.nodes]
    const data = { ...designOrg.data,
      group_mapping: JSON.parse(currentScenario.group_mapping),
      ...toServerLinks({
        nodes: newNodes,
        links: [...relationships, ...designOrg.data.links],
        save: designOrg.data.result.save,
      }),
      resources: newNodes,
      time_utilization: { [employee.id]: employee.time_utilization, ...designOrg.data.time_utilization },
    };
    this.props.saveDesignOrg(this.props.designOrg.data.id, data, this.props.designOrg.data.run)
    /*.then(res => {
      this.props.getDesignOrg(data.id);
    });*/
    
  };

  updateEmployee = (employee) => {
    const designOrg = this.props.designOrg;
    // Dont overwrite if the employee does not exist
    if (!designOrg.data.nodes.find(n => n.id === employee.id)) {
      return;
    }
    
    const scenarios = designOrg.data.scenarios;
    const currentScenario = scenarios.find(scenario => scenario.id === this.state.currentScenario['value']);
    const data = { ...designOrg.data,
      nodes: designOrg.data.nodes.map((n) => (n.id === employee.id ? employee : n)),
      time_utilization: { [employee.id]: employee.time_utilization, ...designOrg.data.time_utilization },
      group_mapping: JSON.parse(currentScenario.group_mapping),
      ...toServerLinks({
        nodes: designOrg.data.nodes,
        links: designOrg.data.links,
        save: designOrg.data.result.save,
      }),
    };
    this.props.saveDesignOrg(this.props.designOrg.data.id, data, this.props.designOrg.data.run);
  };

  setEmployees = (employees) => {
    const designOrg = this.props.designOrg;
    
    const new_employees = arrayReducer(designOrg.data.nodes, employees);

    const scenarios = designOrg.data.scenarios;
    const currentScenario = scenarios.find(scenario => scenario.id === this.state.currentScenario['value']);
    let groupMapping = JSON.parse(currentScenario.group_mapping) || [];
    employees.payload.forEach((employee) => {
      if (employee.group.length > 2) {
        if (!groupMapping.find(g => g.id === employee.group)) {
          groupMapping.push({
            id: employee.group,
            name: employee.organization_unit,
          });
      }
      }
    });
    const data = { ...designOrg.data,
      nodes: new_employees,
      time_utilization: { ...designOrg.data.time_utilization },
      group_mapping: groupMapping,
      ...toServerLinks({
        nodes: new_employees,
        links: designOrg.data.links,
        save: designOrg.data.result.save,
      }),
    };
    this.props.saveDesignOrg(this.props.designOrg.data.id, data, this.props.designOrg.data.run);
  };

  onCreateScenario = (option) => {
    this.setState({ scenarios: [...this.state.scenarios, option] }, () => {
      this.onChangeScenario({
        label: option.name,
        value: option.id,
      });
    });
  }

  onChangeScenario = (option) => {
    const { designOrg } = this.props;
    if (option.value === 0) {
      this.toggle(true);
    } else {
      this.setState({ currentScenario: option }, () => {
        changeScenario({
          design: designOrg.data.id,
          scenario: option.value,
        }).then(res => {
          this.props.getDesignOrg(designOrg.data.id);
        });
        // getDesign()
      });
    }
  }

  toggle = (status) => {
    let nextStatus;
    if (typeof status === 'boolean') {
      nextStatus = status;
    } else {
      nextStatus = !this.state.showNewScenario;
    }
    this.setState({ showNewScenario: nextStatus });
  }

  render() {
    const { designOrg } = this.props;
    const {
      activeTab,
      activeActivity,
      showNewScenario,
      isMaximized,
      scenarios,
      currentScenario,
      shouldHideOptimizeTypes,
    } = this.state;
    if (designOrg.loading) {
      return <Loading text="Loading data..." />;
    }
    if (designOrg.errors) {
      return <Error text={designOrg.errors} />;
    }
    const { data } = designOrg;
    const {
      nodes,
      links,
      activities,
      activityOptions,
      groupOptions,
      activityMap,
      title,
    } = data;
    const colorOptions = {};
    nodes.forEach((node) => {
      colorOptions[node.group] = getColorByName(node.groupName);
    });
    const { graphFilter, graphTermFilter, setGraphFilter } = this.props;
    let options = scenarios.map(item => ({
      value: item.id,
      label: item.name,
    }));

    if (data.run) {
      return <ProgressChart data={data} />;
    }

    options.push(
      {
        value: 0,
        label: '+ Add New Scenario',
      },
    );

    return (
      <div className="design-org">
        <div className="content__header py-4 d-lg-flex align-items-center">
          <h3 className="ellipsis">
            {title}
          </h3>
          <div className="ms-auto d-flex">
            <div style={{ width: 300 }}>
              <Select
                className="my-auto me-3"
                name="scenarios"
                placeholder="Select Scenario"
                options={options}
                onChange={this.onChangeScenario}
                value={currentScenario}
              />
            </div>
            <Button
              className="button"
              text="Close"
              onClick={() => {
                this.props.navigate.navigate('/design');
              }}
            />
          </div>
        </div>
        <Portalable portal={isMaximized} className="design-org">
          <Nav tabs className="design-navs">
            <NavItem>
              <NavLink active={activeTab === 0}
                onClick={() => this.setActiveTab(0)}
                className="design-nav">
                Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 1}
                onClick={() => this.setActiveTab(1)}
                className="design-nav">
                Organization
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 111}
                onClick={() => this.setActiveTab(111)}
                className="design-nav">
                Employees
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 112}
                onClick={() => this.setActiveTab(112)}
                className="design-nav">
                Spans & Layers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 11}
                onClick={() => this.setActiveTab(11)}
                className="design-nav">
                OrgChart
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 6}
                onClick={() => this.setActiveTab(6)}
                className="design-nav">
                Activities
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 2}
                onClick={() => this.setActiveTab(2)}
                className="design-nav">
                Alignment
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 3}
                onClick={() => this.setActiveTab(3)}
                className="design-nav">
                Communication
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 4}
                onClick={() => this.setActiveTab(4)}
                className="design-nav">
                Time Utilization
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 21}
                onClick={() => this.setActiveTab(21)}
                className="design-nav">
                AI agents
              </NavLink>
            </NavItem>
            {/*<NavItem>
              <NavLink active={activeTab === 5}
                onClick={() => this.setActiveTab(5)}
                className="design-nav">
                Network Graph
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink active={activeTab === 7}
                onClick={() => this.setActiveTab(7)}
                className="design-nav">
                Automation
              </NavLink>
            </NavItem>*/}
            {!isMaximized && <GraphToggleMaximize className="maximize-btn"
              onClick={() => this.setMaximized(!isMaximized)} />}
            {isMaximized && <GraphToggleMinimize className="maximize-btn"
              onClick={() => this.setMaximized(!isMaximized)} />}
          </Nav>
          <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
              <DashboardTab
                data={data}
                employees={getTermFilteredNode(nodes, graphTermFilter)}
                relationships={getTermFilteredLinks(links, graphTermFilter)}
                activities={getTermFilteredActivities(activityOptions, graphTermFilter)}
                isMaximized={isMaximized}
              />
            </TabPane>
            <TabPane tabId={1}>
              {1 === activeTab && (
                <>
                  <div className="row p-2">
                    <div className="col-lg-7">
                      <LabelSelector
                        data={data}
                        labels={this.state.labels}
                        setLabels={this.setLabels}
                      />
                    </div>
                    <div className="col-lg-5">
                      {shouldHideOptimizeTypes ? null:(
                        <OptimizeTypes
                          data={data}
                          handleOptimizeTypeChange={this.handleOptimizeTypeChange}
                        />
                      )}
                    </div>
                  </div>
                  <OrganizationTab
                    ref={(ref) => {
                      this.groupListRef = ref;
                      ref?.updateCardSize();
                    }}
                    data={data}
                    save={this.submitDesign}
                    getDesignOrg={this.props.getDesignOrg}
                    labels={this.state.labels}
                    termFilter={graphTermFilter}
                  />
                </>
              )}
            </TabPane>
            <TabPane tabId={111}>
              {111 === activeTab && (
                <EmployeesTab
                  designOrgData={data}
                  name={''}
                  website={''}
                  businessDetails={{ businessType: '', businessSize: '' }}
                  businessDescription={''}
                  activities={activities}
                  employees={nodes}
                  setEmployees={this.setEmployees}
                  tabType="DESIGN"
                />
              )}
            </TabPane>
            <TabPane tabId={112}>
              {112 === activeTab && (
                <SpansAndLayersTab
                  data={data}
                  employees={nodes}
                />
              )}
            </TabPane>
            <TabPane tabId={11}>
              {11 === activeTab && (
                <OrgChartTab
                  employees={getTermFilteredNode(nodes, graphTermFilter)}
                  isMaximized={isMaximized}
                />
              )}
            </TabPane>
            <TabPane tabId={6}>
              <ActivitiesTab
                data={data}
                nodes={getTermFilteredNode(nodes, graphTermFilter)}
                groupMapping={getTermFilteredScenarioGroups(groupOptions, graphTermFilter)}
                links={getTermFilteredLinks(links, graphTermFilter)}
                activities={getTermFilteredActivities(activityOptions, graphTermFilter)}
                setActivities={this.setActivities}
                setActiveActivity={this.setAutomationActivity}
                isMaximized={isMaximized}
              />
            </TabPane>
            <TabPane tabId={2}>
              {2 === activeTab && <div className="row">
                <AlignmentTab
                  data={data}
                  updateResponsibilityMap={this.onUpdateResponsibilityMap}
                  nodes={getTermFilteredNode(nodes, graphTermFilter)}
                  groupMapping={getTermFilteredScenarioGroups(groupOptions, graphTermFilter)}
                  links={getTermFilteredLinks(links, graphTermFilter)}
                  activities={getTermFilteredActivities(activityOptions, graphTermFilter)}
                  setAgentActivity={this.setAgentActivity}
                  setAgentTeam={this.setAgentTeam}
                  setActiveTab={this.setActiveTab}
                  isMaximized={isMaximized}
                />
              </div>}
            </TabPane>
            <TabPane tabId={3}>
              {3 === activeTab && (
                <div className="row design-matrixview">
                  <DesignMatrixView 
                    data={data}
                    getDesignOrg={this.props.getDesignOrg}
                  />
                </div>
              )}
            </TabPane>
            <TabPane tabId={4}>
              {4 === activeTab && 
              <TimeUtilizationTab
                timeUtilization={data.time_utilization}
                activityMap={activityMap}
                nodes={_.keyBy(getTermFilteredNode(nodes, graphTermFilter), 'id')}
                activities={getTermFilteredActivities(activityOptions, graphTermFilter)}
                links={getTermFilteredLinks(links, graphTermFilter)}
                designOrg={designOrg}
              />}
            </TabPane>
            <TabPane tabId={21}>
              {21 === activeTab && <div className="row">
                <AIAgentTab 
                  agentTeam={this.state.agentTeam || ''}
                  setAgentTeam={this.setAgentTeam}
                  agentActivity={this.state.agentActivity || ''}
                  setAgentActivity={this.setAgentActivity}
                  employees={getTermFilteredNode(nodes, graphTermFilter)}
                  time_utilization={data.time_utilization}
                  addEmployee={this.addEmployee}
                  updateEmployee={this.updateEmployee}
                  activities={getTermFilteredActivities(activityOptions, graphTermFilter)}
                  relationships={getTermFilteredLinks(links, graphTermFilter)}
                  groupMapping={getTermFilteredScenarioGroups(groupOptions, graphTermFilter)}
                  data={data}
                  isMaximized={isMaximized}
                  selectedAgent={this.state.selectedAgent}
                  setSelectedAgent={this.setSelectedAgent}
                />
              </div>}
            </TabPane>
            {/*<TabPane tabId={5}>
              {5 === activeTab && <GraphView
                data={data}
                colorOptions={colorOptions}
                activityOptions={activityOptions || []}
                activityMap={activityMap}
                graphFilter={graphFilter}
                graphTermFilter={graphTermFilter}
                setGraphFilter={setGraphFilter}
              />}
            </TabPane>
            <TabPane tabId={7}>
              {7 === activeTab && (
                <AutomationTab
                  data={data}
                  updateResponsibilityMap={this.onUpdateResponsibilityMap}
                  nodes={getTermFilteredNode(nodes, graphTermFilter)}
                  groupMapping={getTermFilteredScenarioGroups(groupOptions, graphTermFilter)}
                  links={getTermFilteredLinks(links, graphTermFilter)}
                  activities={getTermFilteredActivities(activityOptions, graphTermFilter)}
                  activeActivity={activeActivity}
                  setActiveActivity={this.setAutomationActivity}
                  setActivities={this.setActivities}
                  isMaximized={isMaximized}
                />
              )}
            </TabPane>*/}
          </TabContent>
        </Portalable>
        <NewScenarioModal
          showModal={showNewScenario}
          design={designOrg.data.id}
          scenario={+currentScenario.value}
          toggle={this.toggle}
          onCreateScenario={this.onCreateScenario}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  designOrg: state.designOrg,
  savedDesign: state.savedDesign,
  graphFilter: state.designGraphFilter,
  graphTermFilter: state.designMatrixTermFilter,
});

const mapDispatchToProps = {
  getDesignOrg,
  saveDesignOrg,
  setGraphFilter,
  setEfficiencyCoefficient,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DesignOrg));
