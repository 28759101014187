import React, { useMemo, useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import LoadingCellRenderer from 'components/LoadingCellRenderer';
import ManagerRoleDropdownEditor from 'components/ManagerRoleDropdownEditor';

const RoleAllocationMatrix = ({ matrix, setMatrix, roles, isLoading, validateInput }) => {
  const [agGridContext, setAgGridContext] = useState({});
  const gridRef = useRef();
  const prevRowDataRef = useRef();

  // Calculate row data with totals
  const rowData = useMemo(() => {
    const matrixData = Array.isArray(matrix) ? matrix : [];

    let totalRow = { id: 'total', name: 'Total FTE' };

    const data = matrixData.map((row) => {
      if (!roles) return [];
      const manager_role_id = row?.manager_role_id ?? (roles.length > 0 ? roles[0].id : '');
      const rowData = { id: row.id, abbreviation: row.abbreviation, name: row.name, manager_role_id: manager_role_id };
      let rowSum = 1;

      row.columns.forEach((column) => {
        rowData[column.id] = column.value;
        rowSum += Number(column.value) || 0;

        // Accumulate column sums for total row
        totalRow[column.id] = (totalRow[column.id] || 0) + (Number(column.value) || 0);
      });

      rowData.total = rowSum; // Add total for this row +  number of managers which = matrix.length (number of units)
      return rowData;
    });

    // Add the total column sum for the total row
    totalRow.total = Object.values(totalRow)
      .filter((v) => typeof v === 'number')
      .reduce((a, b) => a + b, 0) + matrix?.length;
    totalRow.manager_role_id = '';

    return [...data, totalRow]; // Append total row to the data
  }, [matrix, roles]);

  useEffect(() => {
    setAgGridContext({ isLoading, prevRowData: prevRowDataRef.current });
    prevRowDataRef.current = rowData;

    if (gridRef.current?.api) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [rowData, isLoading]);

  // Column Definitions with Total column
  const columnDefs = useMemo(() => {
    const localMatrix = Array.isArray(matrix) ? matrix : [];

    const baseColumnConfig = {
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      cellClass: 'ag-cell-prevent-word-split',
    };

    return [
      { headerName: 'ID', field: 'id', editable: false, hide: true, minWidth: 100 },
      { headerName: 'Organization Unit', field: 'name', editable: false, minWidth: 200, ...baseColumnConfig },
      {
        headerName: 'Total FTE',
        field: 'total',
        cellClass: 'ag-cell-total',
        editable: false,
        minWidth: 80,
        width: 80,
        valueGetter: (params) => params.data.total,
        flex: 0,
        ...baseColumnConfig,
      },
      {
        headerName: 'Manager Role',
        field: 'manager_role_id',
        editable: true,
        cellRendererFramework: ManagerRoleDropdownEditor,
        cellEditor: 'ManagerRoleDropdownEditor',
        cellEditorParams: {
          options: roles.map(role => ({ key: role.id, label: role.name })),
          isLoading: isLoading,
        },
        cellRendererParams: (params) => ({
          totalFTE: matrix?.length,
          options: roles.map(role => ({ key: role.id, label: role.name })),
        }),
        valueFormatter: (params) => {
          const role = roles.find((r) => r.id === params.value);
          return role ? role.name : '';
        },
        tooltipValueGetter: (params) => {
          const role = roles.find((r) => r.id === params.value);
          return role ? role.name : '';
        },
        minWidth: 150,
        ...baseColumnConfig,
      }
      ,
      ...(localMatrix[0]?.columns.map((column) => ({
        headerName: column.abbreviation,
        field: String(column.id),
        headerClass: 'ag-header-cell-center',
        cellClass: (params) =>
          params.data.id === 'total' ? 'ag-cell-total ag-cell-center' : 'ag-cell-center',
        editable: params => params.data.id !== 'total',
        minWidth: 70,
        headerTooltip: column.name,
        cellRendererFramework: LoadingCellRenderer,
        cellRendererParams: { isLoading },
        ...baseColumnConfig,
      })) || []),
    ];
  }, [matrix, roles, isLoading]);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.refreshCells({ force: true });
    }
  }, [isLoading, gridRef]);

  useEffect(() => {
    if (gridRef.current?.api) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, [columnDefs, rowData]);

  const handleCellValueChange = (params) => {
    if (params.colDef.field === 'total_fte') return; // Prevent editing the total row

    if (params.colDef.field === 'manager_role_id') {
      setMatrix({
        type: 'set_row_parameter',
        payload: {
          rowId: params.data.id,
          key: params.colDef.field,
          value: params.newValue,
        },
      });
      return;
    } 
    if (validateInput(params.newValue)) {
      setMatrix({
        type: 'set_cell',
        payload: {
          rowId: params.data.id,
          columnId: params.colDef.field,
          value: params.newValue,
        },
      });
      return;
    } else {
      params.node.setDataValue(params.colDef.field, params.oldValue);
      console.error('Invalid input: only positive numbers are allowed.');
    }
  };

  return (
    matrix && (
      <div className="mt-2 ag-theme-alpine ag-row grid-height-400">
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          rowData={rowData}
          context={agGridContext}
          tooltipShowDelay={0}
          onCellValueChanged={handleCellValueChange}
          components={{
            classificationDropdownEditor: ManagerRoleDropdownEditor,
          }}
        />
      </div>
    )
  );
};

export default RoleAllocationMatrix;
