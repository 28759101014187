import React, { useCallback, useMemo, useEffect, useState } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import SpansAndLayersChart from 'components/SpansLayersChart';
import { getNumDirectSubordinates } from 'lib/helper';
import { postSearchApi } from 'api/chat';
import Loading from 'components/Loading';
import KpiCard from 'components/KpiCard';
import RatioBar from 'components/RatioBar';
import _, { isEqual } from 'lodash';

const SpansAndLayersTab = ({ data, employees }) => {
  const designId = data.id;
  const [spanPerLayer, setSpanPerLayer] = useState([]);
  const [spans, setSpans] = useState([]);
  const [spanFrequencyRange, setSpanFrequncyRange] = useState({ min: 0, max: 20 });
  const [spanRange, setSpanRange] = useState({ min: 0, max: 20 });
  const [numEmployeeTypeCounts, setNumEmployeeTypeCounts] = useState({});
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [spansAndLayersAnalysis, setSpansAndLayersAnalysis] = useState('');
  const [dashboardMetrics, setDashboardMetrics] = useState([]);

  const calcSpansAndLayers = useMemo(() => {
    if (!employees || employees.length === 0) {
      return [];
    }
    const root = employees.find((employee) => !employee.reporting_to);
    const spans = [];
    const layers = [];

    const queue = [{ layer: 1, employee: root }];
    const visited = new Set();

    while (queue.length > 0) {
      const { layer, employee } = queue.shift();
      if (visited.has(employee.employee_number)) continue;
      visited.add(employee.employee_number);

      const directSubordinateCount = getNumDirectSubordinates(employees, employee);
      spans.push({ layer, employee_number: employee.employee_number, span: directSubordinateCount });

      const existingLayer = layers.find((item) => item.layer === layer);
      if (existingLayer) {
        existingLayer.totalFTE += employee.fte;
        existingLayer.totalSalary += Number(employee.salary) || 0;
        existingLayer.employees.push(employee);
      } else {
        layers.push({ layer, totalFTE: employee.fte, totalSalary: Number(employee.salary) || 0, employees: [employee] });
      }

      const children = employees
        .filter((e) => e.reporting_to === employee.employee_number)
        .map((e) => ({ layer: layer + 1, employee: e }));
      queue.push(...children);
    }

    const avgSpanPerLayers = layers.map((item) => {
      const avg = item.employees.reduce((acc, employee) => {
        return acc + getNumDirectSubordinates(employees, employee);
      }, 0) / item.employees.length;
    
      return { ...item, avgFte: parseFloat(avg.toFixed(1)) };
    });

    const avgSalaryPerLayers = avgSpanPerLayers.map((item) => {
      const avg = item.employees.reduce((acc, employee) => {
        return acc + Number(employee.salary) || 0;
      }, 0) / item.employees.length;

      return { ...item, avgSalary: parseFloat(avg.toFixed(1)) };
    });
  

    return { layers: avgSalaryPerLayers, spans };
  }, [employees]);

  const spanCountPerLayer = useMemo(() => {
    if (!spans) return [];

    const uniqueLayersPerSpan = spans.reduce((acc, span) => {
      if (!acc[span.span] ) {
        acc[span.span] = { span: span.span };
      }
      acc[span.span][span.layer] = (acc[span.span][span.layer] || 0) + 1;
      return acc;
    }, {});

    delete uniqueLayersPerSpan[0];

    return Object.values(uniqueLayersPerSpan).sort((a, b) => a.span - b.span);
  }, [spans]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const { layers, spans } = calcSpansAndLayers;
      setSpanPerLayer(layers || []);
      setSpans(spans || []);
        
      // Count occurrences of each span value
      const spanCounts = spans.reduce((acc, span) => {
        acc[span.span] = (acc[span.span] || 0) + 1;
        return acc;
      }, {});
  
      // Get min and max span frequencies
      const spanFrequencies = Object.values(spanCounts);
      delete spanFrequencies[0];
      setSpanFrequncyRange({
        min: Math.min(...spanFrequencies.filter((item) => item > 0)),
        max: Math.max(...spanFrequencies.filter((item) => item > 0)),
      });
      const spansList = spanCountPerLayer.map(layer => layer.span);
      setSpanRange({
        min: Math.min(...spansList || []),
        max: Math.max(...spansList || []),
      });
      const numEmployees = employees.reduce((acc, employee) => acc + parseFloat(employee.fte), 0);
      const numManagers = employees.filter((employee) => getNumDirectSubordinates(employees, employee) > 0).length;
      const numIndividualContributors = numEmployees - numManagers;

      setNumEmployeeTypeCounts(() => ({ numEmployees, numManagers, numIndividualContributors }));
    }, 100); // Small delay to avoid rapid re-renders
  
    return () => clearTimeout(timeout);
  }, [calcSpansAndLayers, employees, spanCountPerLayer]);

  const calcDashboardMetrics = useCallback(() => {
    const numLayers = spanPerLayer.length || 0;
    const avgSpan = spanPerLayer.reduce((acc, layer) => acc + layer.avgFte, 0) / numLayers;
    const _spanRange = {
      min: spanRange.min ?? 0,
      max: spanRange.max ?? 0,
    };
    const numEmployees = numEmployeeTypeCounts.numEmployees || 0;
    const numManagers = numEmployeeTypeCounts.numManagers || 0;
    const numIndividualContributors = numEmployeeTypeCounts.numIndividualContributors || 0;
    return [
      { title: 'Layers', value: numLayers.toLocaleString('no-NO'), icon: '🧱', unit: '' },
      { title: 'Span of Control', value: avgSpan.toFixed(1).toLocaleString('no-NO'), icon: '🤹', unit: '' },
      { title: 'Span Range', value: `${_spanRange.min} - ${_spanRange.max}`, icon: '🎚️', unit: '' },
      { title: 'Employees', value: numEmployees.toLocaleString('no-NO'), icon: '👥', unit: '' },
      { title: 'Managers', value: numManagers.toLocaleString('no-NO'), icon: '🎯', unit: '' },
      { title: 'Individual Contributors', value: numIndividualContributors.toLocaleString('no-NO'), icon: '🔧', unit: '' },
    ];
  }, [numEmployeeTypeCounts, spanPerLayer, spanRange]);

  useEffect(() => {
     const UpdatedDashboardMetrics = calcDashboardMetrics();
    if (!isEqual(dashboardMetrics, UpdatedDashboardMetrics)) {
      setDashboardMetrics(UpdatedDashboardMetrics);
    }
  }, [calcDashboardMetrics, dashboardMetrics]);

  const columns = useMemo(() => {
    const uniqueLayerKeys = new Set();
    spanCountPerLayer.forEach(row => {
      Object.keys(row).forEach(key => {
        if (key !== 'span') uniqueLayerKeys.add(Number(key));
      });
    });

    return [
      { headerName: 'Span', field: 'span', editable: false, width: 100 },
      ...Array.from(uniqueLayerKeys).sort((a, b) => a - b).map(layer => ({
        headerName: `Layer ${layer}`,
        field: String(layer),
        headerClass: 'ag-header-cell-center',
        cellClass: 'ag-cell-center',
        editable: false,
        minWidth: 70,
        headerTooltip: `Layer ${layer}`,
        cellClassRules: {
          'cell-high': (params) => params.value > spanFrequencyRange.max * 0.7,
          'cell-medium': (params) => params.value > spanFrequencyRange.max * 0.5 && params.value <= spanFrequencyRange.max * 0.8,
          'cell-low': (params) => params.value > spanFrequencyRange.max * 0.2 && params.value <= spanFrequencyRange.max * 0.5,
          'cell-very-low': (params) => params.value <= spanFrequencyRange.max * 0.3,
        },
      })),
    ];
  }, [spanCountPerLayer, spanFrequencyRange]);

  const analyzeSpandAndLayers = async () => {
    if (isAnalyzing) return;

    setIsAnalyzing(true);
    const apiPayload = {
      prompt_template: 'analyze_spans_and_layers',
      design_id: designId,
      employees: employees ? employees : [],
      span_per_layer: spanPerLayer ? spanPerLayer : {},
      span_count_per_layer: spanCountPerLayer ? spanCountPerLayer : [],
    };

    try {
      const apiResponse = await postSearchApi(apiPayload);
      const _spansAndLayersAnalysis = apiResponse.message;

      try {
        setSpansAndLayersAnalysis(() => _spansAndLayersAnalysis);
      } catch (error) {
        console.error('Error parsing API response:', error);
      }
    } catch (error) {
      console.error('Error analyzing spans and layers:', error);
    }
    setIsAnalyzing(false);
  };

  const agGridHeight = useMemo(() => {
    if (spanCountPerLayer.length > 0) {
      return Math.max(400, spanCountPerLayer.length * 43 + 50);
    }
    return 100;
  }
  , [spanCountPerLayer]);

  return (
    <div className="function-tab">
      <div className="function-tab-header"></div>
      
      <div className="automation-tab-header dashboard-container">
        <div className='container-wide-row'>
          <h2>Spans & Layers</h2>
          <RatioBar label1='Managers' label2="Individual Contributors" value1={numEmployeeTypeCounts.numManagers || 1} value2={numEmployeeTypeCounts.numIndividualContributors || 1} unit="" />
          <KpiCard kpiData={dashboardMetrics}  className="mt-2" />
        </div>
        <div className="chart-container mt-5">
          <SpansAndLayersChart data={spanPerLayer} />
        </div>
        <div className="grid-container">
          <div className="ag-theme-alpine ag-grid-wrapper" 
              style={{ height: `${agGridHeight}px`, width: '100%' }}>
            <h3>Detailed Count of spans per layer</h3>
            <AgGridReact
              columnDefs={columns}
              rowData={spanCountPerLayer}
            />
          </div>
        </div>
        <div className="">
          <button 
            onClick={() => analyzeSpandAndLayers()} 
            disabled={isAnalyzing}
            className="button ms-2" 
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Evaluate Spans & Layers
            {isAnalyzing ? (
              <Loading style={{ marginLeft: 'auto', height: '35px' }} />
            ) : (
              <span className="material-symbols-outlined">neurology</span>
            )}
          </button>
          {spansAndLayersAnalysis && (
            <div className="analysis-text-card">
              <div className="analysis-text-card-header">
                Spans & Layers Evaluation
              </div>
              <h4>1. Hierarchy Depth & Structural Complexity</h4>
              <p>{spansAndLayersAnalysis.hierarchy_depth_and_structural_complexity}</p>
              <br />
              <h4>2. Span of Control & Managerial Load</h4>
              <p>{spansAndLayersAnalysis.span_of_control_and_managerial_load}</p>
              <br />
              <h4>3. Workforce Distribution & Functional Balance</h4>
              <p>{spansAndLayersAnalysis.workforce_distribution_and_functional_balance}</p>
              <br />
              <h4>4. Decision-Making Efficiency & Bureaucracy Impact</h4>
              <p>{spansAndLayersAnalysis.decision_making_efficiency_and_bureaucracy_impact}</p>
              <br />
              <h4>5. Optimization & Scalability Potential</h4>
              <p>{spansAndLayersAnalysis.optimization_and_scalability_potential}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SpansAndLayersTab;
